


import '../Styles/Medyaf.css';
import React, {useState,useEffect} from 'react';
import master from "../Images/Master.svg.png"
import visa from "../Images/Visa.svg.png"
import { BsImages } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import Footer from '../Components/Footer';
import prepare from "../Images/prepare.svg"
import { Link } from 'react-router-dom';
function MenuBooked() {
    const { t, i18n } = useTranslation();
 
  
    return (
<div>
<div style={{marginTop:"5em",marginBottom:"5em"}} className='container orderSuccess'>
   
 <div className='shareForm '>
 <img src={prepare}/>
<p >
{t("mainMenu.book_success")}
</p>
<Link style={{display:"flex", justifyContent:"center"}} to={`/order-process/${localStorage.getItem("orderId")}`}>
    <button style={{marginTop:"1em"}}>{t("payment.track_order")}</button>
</Link>
 </div>
  </div>
  <Footer/>
  </div>
    );
}

export default MenuBooked;
