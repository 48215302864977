
import '../Styles/Medyaf.css';
import React, {useState} from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useParams,generatePath } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { HiOutlineCash } from 'react-icons/hi';
import { BsPeople } from 'react-icons/bs';
import { BackgroundImage } from 'react-image-and-background-image-fade'

function MainMenuItems(props) {
  const { t, i18n } = useTranslation();
  let history = useNavigate();
  const id= props.id;

 
  //on choosing menu user is redirected to /menu (MenuDetails component found in /Medyaf) with menu id as params
  const handleProceed = (e) => {
     history(generatePath("/menu/:id", { id }));
     
   }; 
    return (

  
    <div className="col-lg-4 col-sm-6">
      <div className='menuContainer'>
      <div style={{minWidth:"auto"}} className=" medyafMenu">
     {/* <div className='bckg ' style={{backgroundImage:`url(${props.image})`}}>


    </div>*/}
 
      <BackgroundImage
        src={props.image}
       width="100%"
       lazyLoad
       className="bckg"
      />


      <div className='paddingMenu'>
        <div  style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
        <p style={{color:"#633235"}}>{props.name}</p></div>
      <div className='medyafDetails'>
      <p><HiOutlineCash size={20} className={i18n.language=="ar"?'mmIconsAr':'mmIcon'}/> {props.price} {t("nav.SAR")}</p>
      <p><BsPeople size={20} className={i18n.language=="ar"?'mmIconsAr2':'mmIcon2'}/>{t("mainMenu.min_persons")} {props.min} {t("mainMenu.person")}</p></div>
 
      </div>
      <button onClick={handleProceed} >{t("mainMenu.openMenu")}</button>
      </div></div>
    </div>
   
    );
}

export default MainMenuItems;
