

import '../Styles/Medyaf.css';
import React, {useState,useEffect,useRef} from 'react';
import { AiOutlineTwitter } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import Navbar from '../Components/Navbar';
import tiktok from "../Images/tiktok.svg"

import whatsapp from "../Images/whatsapp.png"

import call from "../Images/call.svg"
import Facebook from "../Images/Facebook.svg"
import twitter from "../Images/twitter.svg"
import instagram from "../Images/instagram.svg"
import CarouselComp from '../Components/CarouselCom';
import { useTranslation } from 'react-i18next';
import { phones ,contact} from '../api'
import Footer from '../Components/Footer';
import BeatLoader from "react-spinners/BeatLoader";
import * as yup from "yup"
import {useFormik} from "formik";
import ContactSuccess from '../Components/ContactSuccess';
function ContactUs() {
  const { t, i18n } = useTranslation();
const [success,setSuccess]=useState(false);
  const[phone,setphone]=useState([]);

  const [loading,setLoading]=useState(true);
   
   let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;
let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
let emailRegx =/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const Schema= yup.object().shape({
phone: yup.string().required(("validation.phone_required")).matches(phoneRegEx,{message:("validation.phone_not_valid")}),
name: yup.string()
.required(("validation.name_required")).matches(nameRegEx,{message:("validation.name_not_valid")}),
message: yup.string()
.required(("validation.message_required")),
email: yup.string()
.required(("validation.email_required")).matches(emailRegx,{message:("validation.invalid_email")}),

})


const formik = useFormik({
initialValues:{
  name: '',
 phone: '',
email: '',
  message: '',
 
},
validationSchema: Schema,
onSubmit,
});
const emailRef=useRef();
    const phoneRef=useRef();
    const messageRef=useRef();
  
    const nameRef=useRef();

  
  useEffect(() => {
    phones().then(response => {
     const data=response.data.data;
     setphone(data);
  
  console.log(response);
  setLoading(false);
})
  
  
 
   },[]);



   async function onSubmit(event){
  
    const data={
 
      name: nameRef.current.value,
      phone: phoneRef.current.value,
      email: emailRef.current.value,
      msg: messageRef.current.value
    }
      
    contact(data).then((response) => {
        console.log(response)
        
  window.location.href = `https://api.whatsapp.com/send/?phone=966532222067&text=${"الاسم:"}+${data.name}%0A${"رقم الجوال:"}+${data.phone}%0A${"البريد الإلكتروني:"}+${data.email}%0A${"الرسالة:"}+${data.msg}`
  

   // setSuccess(true);
   // formik.resetForm()
          })   } 


    return (
      <div>{loading?<div className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
      <div>
     
     {success? (
        <ContactSuccess setModal={setSuccess}/>
      ):null}

                        {/*
<div style={{marginTop:"80px",marginBottom:"5em"}} className='container'>
    <p className='contact'>{t("contact.mudiaf_contact")}</p>
    <div className='phones'>
      
        <a href={`tel:${phone. mdiafPhone1}`}>{phone. mdiafPhone1}</a>
        <a  href={`tel:${phone. mdiafPhone2}`}>{phone. mdiafPhone2}</a>
        <a  href={`tel:${phone. mdiafPhone3}`}>{phone. mdiafPhone3}</a>
        <a  href={`tel:${phone. mdiafPhone4}`}>{phone. mdiafPhone4}</a>
    </div>
    <p className='contact'>{t("contact.followUs")}</p>
    <div className={i18n.language=="ar"?'socials socialsAr':'socials'}>
     <a href='https://www.instagram.com/medhyafsa/' target='_blank'><AiFillInstagram size={40} className='socialIcon'/></a> 
     <a href='https://twitter.com/medhyafsa' target='_blank'>  <AiOutlineTwitter size={40} className='socialIcon'/></a>
       <a href='https://www.facebook.com/medhyafsa' target='_blank'>    <BsFacebook size={35} className='socialIcon'/></a>
    </div>
    </div>*/}
<div style={{marginBottom:"5em"}}>
<CarouselComp/>
  
  <div className='container-fluid fluid'>

  <p className='cuHeader'>  {t("contact.KnowMore")}</p>
<div className='row gy-5'>
<div className='col-lg-6'>
  <div className='alignCu'>
<p className='cuTitles'>     {t("contact.form")}</p>
<div className='contactForm'>
<form name="contactUs" onSubmit={formik.handleSubmit} >
 
<label> {t("Login.yourName")}</label>
<input type="text" placeholder= {t("Login.yourName")} value={formik.values.name} id="name" name="name"  ref={nameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.name && formik.touched.name?"invalid":null}/>
<span>{formik.errors.name && formik.touched.name?t(`${formik.errors.name}`):null}</span>


<label>  {t("Login.enterPhone")}</label>
<input type="text" placeholder={t("Login.yourPhone")} value={formik.values.phone} id="phone" name="phone"  ref={phoneRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone?"invalid":null}/>
<span>{formik.errors.phone && formik.touched.phone?t(`${formik.errors.phone}`):null}</span>
 
<label>  {t("contact.email")}</label>
<input type="text" placeholder={t("contact.yourEmail")} value={formik.values.email} id="email" name="email"  ref={emailRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.email && formik.touched.email?"invalid":null}/>
<span>{formik.errors.email && formik.touched.email?t(`${formik.errors.email}`):null}</span>

<label>  {t("contact.message")}</label>
<textarea value={formik.values.message} id="message" name="message"  ref={messageRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.message && formik.touched.message?"invalid":null}/>
<span>{formik.errors.message && formik.touched.message?t(`${formik.errors.message}`):null}</span>

<div style={i18n.language=="ar"?{textAlign:"left",marginTop:"1.5em"}:{textAlign:"right",marginTop:"1.5em"}}>
<button type="submit"> {t("contact.send")}</button>
</div>
</form>
 
  </div>

</div></div>
<div className='col-lg-6'>

  <div >
<p className='cuTitles'>    {t("contact.byPhone")}</p>

<div className='contactPhone'>
   
      {phone.mdiafPhone1?
        <div style={{marginTop:"2em"}}>
      <p>{phone.mdiafPhone1}</p>
      <a href={`tel:${phone.mdiafPhone1}`}>  <button><img className={i18n.language=="ar"?"callIcon":null}  src={call}/></button>

</a>

</div>:null}

      {phone.mdiafPhone2?
      <div>
  
     
  <p>{phone.mdiafPhone2}</p>
  <a href={`tel:${phone.mdiafPhone2}`}>  <button><img className={i18n.language=="ar"?"callIcon":null} src={call}/></button>

</a>

</div>:null}

  {phone.mdiafPhone3?
  <div>
  
  
  
  <p>{phone.mdiafPhone3}</p>
  <a href={`tel:${phone.mdiafPhone3}`}>  <button><img className={i18n.language=="ar"?"callIcon":null}  src={call}/></button>

</a>

  </div>:null}


  {phone.mdiafPhone4?
  <div>
  
     
  <p>{phone. mdiafPhone4}</p>
  <a href={`tel:${phone. mdiafPhone4}`}>  <button><img className={i18n.language=="ar"?"callIcon":null}  src={call}/></button>

</a>

</div>:null}
  </div>
</div>

<div className={i18n.language==="ar"?'cuSocials ':'cuSocials cuSocialsEn'}>
<p className='cuTitles'> {t("contact.followUs")} </p>
<a href='https://www.facebook.com/medhyafsa' target='_blank'> <button style={{margin:"0"}}><img src={Facebook}/></button></a>
<a href='https://twitter.com/medhyafsa' target='_blank'><button className='twitter'><img src={twitter}/></button></a>
<a href='https://www.instagram.com/medhyafsa/' target='_blank'><button><img src={instagram}/></button></a>
<a href='https://www.tiktok.com/@medhyafsa?_t=8fzGcjh1UKg&_r=1'  target='_blank'><button><img width="39px" height="39px" src={tiktok}/></button></a>
<a href='https://wa.me/966532222067' target='_blank'><button><img width="39px" height="39px" src={whatsapp}/></button></a>


</div>
</div>
  </div></div>
    </div>
  <Footer/>
  </div>} </div>
    );
}

export default ContactUs;
