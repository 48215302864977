


import '../Styles/Medyaf.css';
import React, {useEffect, useState} from 'react';
import logo from "../Logo.svg"
import logoAr from "../Images/logoAR3.svg"
import logoEn from "../Images/logoEn.svg"
import { AiOutlineUser } from "react-icons/ai";
import { NavLink,Link } from 'react-router-dom';
import {AiOutlineMenu} from "react-icons/ai";
import {BiMenuAltLeft} from "react-icons/bi";
import {BiMenuAltRight} from "react-icons/bi";
import { LanguageContext } from '../Context/LanguageContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { CustomerInfo } from '../api';
import i18next from 'i18next';
function TopNav() {
  const verified=  JSON.parse(localStorage.getItem("verified"));
    const [toggle,setToggle]=useState(false);
    const [lang,setLang]=useState("ar");
    const name= localStorage.getItem("userName");
    const [header, setHeader] = useState("newNav")
    const token= localStorage.getItem("token");
   
    if(name!==null){
    var firstName = name.split(' ').slice(0,1).join(' ');}
    //const [languageState, SetLanguagState]= useState(language);
    const { t, i18n } = useTranslation();
    const history= useNavigate();
 


    
      const listenScrollEvent = (event) => {
        if (window.scrollY >80) {
         
            setHeader("newNavFixed ")
        }
          else{
            setHeader("newNav")

          }
       
      
      }

      useEffect(()=>{
if(localStorage.getItem("token")){

        CustomerInfo(localStorage.getItem("token")).then((response) => {
 
        }).catch(error => {
          console.log(error)
          if (error.response.status === 401) {

             localStorage.removeItem("token");
             localStorage.removeItem("userPhone");
             localStorage.removeItem("userName");
             localStorage.removeItem("basket");
             
             window.location.reload();
          } else {
            
              console.log(error);
          }
      });}
      },[window.location.pathname])
    
      useEffect(() => {
   
       window.addEventListener('scroll', listenScrollEvent);
      
           return () =>
          window.removeEventListener('scroll', listenScrollEvent);
      }, []);  

    const changeLanguage = (e) => {
       var language = e.target.value;
        i18n.changeLanguage(language);
        localStorage.setItem("language",JSON.stringify(language ));
        setLang(language);
       console.log(i18n.language);
      if(language==="en"){
      document.getElementById("root").style.direction="ltr";
    
      }
      else{
        document.getElementById("root").style.direction="rtl";
      }
      };
 

      useEffect(() => {
    
         
const data=  JSON.parse(localStorage.getItem("language"));
i18n.changeLanguage(data);
setLang(data);


if(i18n.language==="en"){
    document.getElementById("root").style.direction="ltr";
 
    }
    else if(i18n.language==="ar"){
      document.getElementById("root").style.direction="rtl";
    
    }
    else{
     document.getElementById("root").style.direction="rtl";
    }

    if(JSON.parse(localStorage.getItem("language"))==="ar"){
     document.title=" مضياف الحفلات الرومانسية"
 }else {
     document.title="Al Romansiah Medhyaf "

 }
       },[lang]);

 

    return (
        <div className='container-fluid fluid' >
<div id="topNav" className='topNav'>
    <div className='logo'>
        <Link   to="/">
<img  src={i18n.language=="ar"?logoAr:logoEn}/></Link></div>

  
    <div className='navLinks '>
        <div className={toggle?"nav2Mobile":'nav '}>
    <NavLink  to="/" onClick={()=>setToggle(false)} >
                        <p className="links">{t("nav.mudiaf_menus")}</p>
                    </NavLink>
                    <NavLink   to="/services" onClick={()=>setToggle(false)}>  
                        <p className="links" >{t("nav.mudiaf_services")} </p>
                   </NavLink> 


                    <NavLink to="/inquires" onClick={()=>setToggle(false)}>
                        <p className="links">{t("nav.mudiaf_inquires")} </p>
                    
                    </NavLink>
                     
                    <NavLink to="/images" onClick={()=>setToggle(false)} >
                        <p className="links">{t("nav.mudiaf_images")} </p>
                    
                    </NavLink> 
                    <NavLink to="/terms" onClick={()=>setToggle(false)} >
                        <p className="links">{t("nav.t&c")} </p>
                    
                    </NavLink> 

                    <NavLink to="/contact" onClick={()=>setToggle(false)}>  
                   
                        <p className="links">  {t("nav.mudiaf_contact")}</p>
                   
                    </NavLink> 

    </div></div>
    <div className={i18n.language=="ar"?'navContent navContentAr':'navContent'}>
      
    <div className='mobileTogg' style={{margin:"0 20px"}}>
        <select value={lang} onChange={(e)=>changeLanguage(e)}>
<option value={"en"}> English </option>
<option value={"ar"}> العربية </option>
    </select>
    </div>
    {
      !localStorage.getItem("token") || !verified?     <NavLink to="/login" > 
      {console.log(localStorage.getItem("token"))}
      <div className="navProfile">
     <p className='profile'>{t("nav.signIn")} </p></div></NavLink>:
    <NavLink onClick={()=>localStorage.setItem("location","profile")} to="/profile" > <div className="navProfile">
<p className='profile'>{firstName}</p>
  <AiOutlineUser size={23} className={i18n.language=="ar"?'profileIconAr profile' :"profileIcon profile"}/> </div></NavLink>}
  <button onClick={()=> setToggle(!toggle)} className="togg ">{i18n.language=="ar"?<BiMenuAltLeft size={40}/>:<BiMenuAltRight size={40}/>}</button>

</div>
  </div>
  <div className={header}>
  <div className='navigation '>
    <NavLink  to="/">
                        <p className="linksNew">{t("nav.mudiaf_menus")}</p>
                    </NavLink>
                    <p className='partition'>|</p>
                    <NavLink   to="/services" >  
                        <p className="linksNew">{t("nav.mudiaf_services")} </p>
                   </NavLink> 
                   <p className='partition'>|</p>

                    <NavLink to="/inquires" >
                        <p className="linksNew">{t("nav.mudiaf_inquires")} </p>
                    
                    </NavLink>
                    <p className='partition'>|</p>
                    <NavLink to="/images"  >
                        <p className="linksNew">{t("nav.mudiaf_images")} </p>
                    
                    </NavLink> 
                    <p className='partition'>|</p>
                    <NavLink to="/terms"  >
                        <p className="linksNew">{t("nav.t&c")} </p>
                    
                    </NavLink> 
                    <p className='partition'>|</p>

                    <NavLink to="/contact"  >  
                   
                        <p className="linksNew">  {t("nav.mudiaf_contact")}</p>
                   
                    </NavLink> 

    </div>

  </div>
  </div>
    );
}

export default TopNav;
