


import '../Styles/Medyaf.css';
import React, {useState,useEffect} from 'react';
import OrderDetails from '../Components/OrderDetails';
import { useTranslation } from 'react-i18next';
import {mediafOrder} from "../api";
import BeatLoader from "react-spinners/BeatLoader";
import { useParams,generatePath } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
function YourOrders() {
    const { t, i18n } = useTranslation();
    const [orders,setOrders]=useState([]);

const [loading,setLoading]=useState(true);
let history = useNavigate();


//when user clicks on an order's view details button user is redirected to /order-process (OrderProcess component found in /Medyaf) with order id as params
function handleProceed (id) {
   history(generatePath("/order-process/:id", { id }));
   
 }; 

//mediafOrder api call takes user token to fetch user's medyaf orders
    useEffect(() => {
const token = localStorage.getItem("token");
        mediafOrder(token).then((response) => {
console.log(response);
            setOrders(response.data.data.orders);
           // setInProgress(response.data.data.inProgress);
           // setWaitingPayment(response.data.data.waitingPayment);
            setLoading(false);
        }).catch(error => {
            if (error.response.status === 401) {
          
               localStorage.removeItem("token");
               localStorage.removeItem("userPhone");
               localStorage.removeItem("userName");
               localStorage.removeItem("basket");
               localStorage.setItem("location","myOrders");
               history("/login")
            } else {
              
                console.log(error);
            }
        });

    },[]);


  


    return (
        
<div style={{width:"100%"}}>
{loading?<div className='orderLoader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
    <div style={{marginBottom:"5em",background:"transparent",boxShadow:"none",width:"100%"}} className='orderDiv'>



<table>


<tr className=' orderTable orderHeader'>
    <th>{t("profile.order_id")}</th>
    <th>{t("mainMenu.date")}</th>
    <th>{t("profile.paymentMethod")}</th>
    <th>{t("mainMenu.total_price")}</th>
    <th>{t("mainMenu.state")}</th>
    <th> </th>
  </tr>
  
  {
      
    
      orders && orders.map((data,index)=>
         <>
            <tr  key={index}  className='orderTable orderData'>
            <td>{data.id}</td>
            <td>{data.date}</td>
            <td>{data.paymentMethod}</td>
            <td>{data.totalPrice + " " +t("nav.SAR")}</td>
            <td>{data.statusId=== 7 ?t("profile.confirmed"):data.statusId === 8 ?t("profile.next_orders"):data.statusId === 4 ? t("profile.pre_orders"):t("profile.wait_orders")}</td>
            <td><button onClick={()=>handleProceed(data.id)}>{t("mainMenu.show")}</button> </td>
           
           
           
        
            </tr>
         <tr className=' orderTable orderHeader'>
         <th></th>
     </tr></>
            ) 

 
}
{/*
      
     
      inProgress && inProgress.map((data,index)=>
    <>
       <tr  key={index}  className='orderTable orderData'>
       <td>{data.id}</td>
       <td>{data.date}</td>
       <td>{data.paymentMethod}</td>
       <td>{data.totalPrice + " " +t("mainMenu.show")}</td>
       <td>{data.statusId=== 7 ?t("profile.confirmed"):data.statusId === 8 ?t("profile.next_orders"):data.statusId === 4 ?t("profile.pre_orders"):t("profile.wait_orders")}</td>
       <td><button onClick={()=>handleProceed(data.id)}>{t("mainMenu.show")}</button> </td>
      
      
      
   
       </tr>
    <tr className=' orderTable orderHeader'>
    <th></th>
</tr></>
       ) 


*/}
    
{
     /* 
    
      done && inProgress.map((data,index)=>
    <>
       <tr  key={index}  className='orderTable orderData'>
       <td>{data.id}</td>
       <td>{data.date}</td>
       <td>{data.paymentMethod}</td>
       <td>{data.totalPrice + " " +t("mainMenu.show")}</td>
       <td>{data.statusId=== 7 ?t("profile.confirmed"):data.statusId === 8 ?t("profile.next_orders"):data.statusId === 4 ? t("profile.pre_orders"):t("profile.wait_orders")}</td>
       <td><button onClick={()=>handleProceed(data.id)}>{t("mainMenu.show")}</button> </td>
      
      
      
   
       </tr>
    <tr className=' orderTable orderHeader'>
    <th></th>
</tr></>
       ) 

*/
}
   
    </table> 


        {/*




<p className='profileTitle'> {t("profile.my_orders")} </p>

<div>
    <p className='orderTitle'>{t("profile.wait_orders")}</p>
    <div className='container-fluid alignOrder'>
    <div className='row gy-4'>

    {
           !waitingPayment.length==0?
    
           waitingPayment && waitingPayment.map((data,index)=>
            <OrderDetails key={index} date={data.date} id={data.id} image={data.image} time={data.time} address={data.address} num_persons={data.personsNumber} status={data.statusId=== 7 ?t("profile.confirmed"):data.statusId === 8 ?t("profile.next_orders"):data.statusId === 4 ? "done":"waitingPayment"}/>
            
            ) :
            <div className='noOrder'>    {t("profile.no_orders")}</div>

 
}

    
    </div>
</div>
    <hr/>
    </div>
    <div>
    <p className='orderTitle'>{t("profile.next_orders")}</p>
    <div className='container-fluid alignOrder'>
    <div className='row gy-4'>
        
    {
    
    !inProgress.length==0?
    inProgress && inProgress.map((data,index)=>
     <OrderDetails key={index} date={data.date} id={data.id} image={data.image} time={data.time} address={data.address} num_persons={data.personsNumber} status={data.statusId=== 7 ?t("profile.confirmed"):data.statusId === 8 ?t("profile.next_orders"):data.statusId === 4 ? "done":"waitingPayment"}/>
     
     ) :
     <div className='noOrder'>    {t("profile.no_orders")} </div>
}
  
    </div>
</div>
    <hr/>
    </div>
    <div>
    <p className='orderTitle'>{t("profile.pre_orders")}</p>
    <div className='container-fluid alignOrder'>
    <div className='row gy-4'>
    {
     
 
     !done.length==0? 
     done && done.map((data,index)=>
      <OrderDetails key={index} date={data.date} id={data.id} image={data.image} time={data.time} address={data.address} num_persons={data.personsNumber} status={data.statusId=== 7 ?t("profile.confirmed"):data.statusId === 8 ?t("profile.next_orders"):data.statusId === 4 ? "done":"waitingPayment"}/>
      
      ) :
      
   <div className='noOrder'>  {t("profile.no_orders")} </div>
}
  
    </div>
</div>
    <hr/>
    </div> */}
</div>}
</div>

    );
}

export default YourOrders;
