import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import  './ i18next';
import {Suspense}  from 'react';
import SimpleReactLightbox from 'simple-react-lightbox'
import "react-alice-carousel/lib/alice-carousel.css";
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  

  <Suspense fallback={<div>Loading...</div>}>

      <App />
     
      </Suspense>
 

);


