


import '../Styles/Medyaf.css';

import React, {useState,useEffect,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import LoginNav from '../Components/LoginNav';
import { IoIosPhonePortrait } from "react-icons/io";
import {CustomerInfo2,otp,SMS} from "../api";
import { useNavigate } from "react-router-dom";
function Verify() {
const [hidden,setHidden]=useState(false);
const [loggedIn,setLoggedIn]=useState(false);
const { t, i18n } = useTranslation();
const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
let history = useNavigate();
const [errorMsgModel, setErrorMsgModel]=useState(false);
const [arDesc, setArDesc]=useState();
const otpRef=useRef();

    useEffect(() => {

       },[]);
    

       function show() {

        setHidden(!hidden);
        var x = document.getElementById("pass");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }

      function handleSubmit(e){
        e.preventDefault();
      const  data = {
          phoneVerificationCode: otpRef.current.value,
      } 

      const token =   localStorage.getItem("token");

otp(data,token).then((response) => {
        if (response.data.message === "success") {
          localStorage.setItem("verified",JSON.stringify(true))
          console.log(response);
            localStorage.setItem("token",token);
            CustomerInfo2(token).then((response) => {
              localStorage.setItem("userName",response.data.data.name)
              localStorage.setItem("userPhone",response.data.data.phone)
history("/");
console.log(response);
              setLoggedIn(true);})
          
            }

            else {

                 
                setMsgAr("الكود غير صحيح")
                setMsgEn("Invalid code");
                setErrorMsgModel(true);
                console.log(response);
          }


      }).catch(function (error) {
        setMsgAr("الكود غير صحيح")
                setMsgEn("Invalid code");
                setErrorMsgModel(true);
            
            
   
          })

      

      }


      function resend(){

        const token =   localStorage.getItem("token");
        let headers = {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json'
      };

        SMS(headers).then((response) => {
       
                setMsgAr("تم إرسال الكود");
                setMsgEn("Your code has been send");
                setErrorMsgModel(true);
         
        }).catch(function (error) {
            // console.error(error);
        });
    };

    return (
      <div><LoginNav/>
        <div className='container-fluid fluid loginContainer'>
<div className='container-fluid login'>


    <div className='loginTitle'>
        <div style={{marginBottom:"2em"}}>
        <IoIosPhonePortrait size={60} className='fpLogo'/>
<p className='fp-p1'>{t("Login.checkPhone")}</p>
<p className='fp-p1'>{t("Login.verify")}</p></div>
<form className='loginForm fpForm' onSubmit={(e)=>handleSubmit(e)}>
    <div className='fpBody'>
<div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:null}>
<label>{t("Login.EnterVerify")}</label>
<div>

<input type="text"  id="code" name="code"  ref={otpRef} className={errorMsgModel?"invalid":null}/>
</div></div>

<div>
<div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:null}>
{i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}
<p onClick={resend} className='resend'>{t("validation.resend")}</p>
</div>
<button type='submit'>{t("Login.confirm")}</button></div></div>
</form>
</div></div>
</div>

</div>
    );
}

export default Verify;
