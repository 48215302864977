
import './App.css';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import { useState } from 'react';
import BookMenu from './Medyaf/BookMenu';
import MenuDetails from './Medyaf/MenuDetails';
import PartyInquires from './Medyaf/PartyInquires';
import CustomerShares from './Medyaf/CustomerShares';
import ContactUs from './Medyaf/ContactUs';
import AdditionalServices from './Medyaf/AdditionalServices';
import MainMenu from './Medyaf/MainMenu';
import Basket from './Medyaf/Basket';
import TopNav from './Components/TopNav';
import Footer from './Components/Footer';
import Vat from './Components/Vat';
import Profile from './Medyaf/Profile';
import ChargeWallet from './Medyaf/ChargeWallet';
import PaymentSuccess from "./Medyaf/PaymnetSuccess";
import { BrowserRouter,Routes,Route, useLocation,Outlet } from 'react-router-dom';
import Login from './Medyaf/Login';
import Register from './Medyaf/Register';
import ForgotPassword from './Medyaf/ForgotPassword';
import Verify from './Medyaf/Verify';
import UserCode from './Medyaf/UserCode';
import OrderProcess from './Medyaf/OrderProcess';
import BasketSent from './Medyaf/BasketSent';
import NewPassword from './Medyaf/NewPassword';
import NotFound from './Components/NotFound';
import { OrderContext } from './Context/OrderContext';
import OrderList from './Medyaf/OrderList';
import LoggingOut from './Medyaf/LoggingOut';
import PrivateRoutes from './utils/PrivateRoutes';
import TermsAndConditions from './Medyaf/TermsAndConditions';
import { useTranslation } from 'react-i18next';

import { AiFillInstagram } from 'react-icons/ai';
import { BsFacebook ,BsTwitter} from 'react-icons/bs';
import { IoLogoWhatsapp } from 'react-icons/io';
import { FaTiktok } from 'react-icons/fa';
import MenuBooked from './Medyaf/MenuBooked';
function App() {
const [order,setOrder]=useState();
const { i18n } = useTranslation();




  return (
    <div className="App" >
 <BrowserRouter>
<OrderContext.Provider value={{order,setOrder}}>
      <Routes>
 
      <Route
      element={(
        <>
        
          <Outlet />
        </>
      )}
    >
      <Route
        element={(
          <>
            <TopNav />
        
            <Outlet />
          </>
        )}
      >

        <Route
         element={(
          <>
        <div className={i18n.language=="ar"?'socials':'socials socialsEn'}>
                <a target="_blank" href='https://www.instagram.com/medhyafsa/'>
                <AiFillInstagram size={30}/>
              </a>
              <a target="_blank" href='https://www.facebook.com/medhyafsa'>
                <BsFacebook size={25}/>
              </a>
              <a target="_blank" href='https://x.com/medhyafsa?s=21&t=6QI_BJhedH3fPCWJpf_vHg
              '>
                <BsTwitter size={25}/>
              </a>
              <a target="_blank" href='https://wa.me/966532222067'>
                <IoLogoWhatsapp size={28}/>
              </a>
              <a target="_blank" href='https://www.tiktok.com/@medhyafsa?_t=8fzGcjh1UKg&_r=1' style={{marginBottom:"0"}}>
                <FaTiktok size={25}/>
              </a>
            </div>
        
            <Outlet />
          </>
        )}
        >

<Route exact path="/" element={<MainMenu/>}/>
<Route exact path="/menu/:id" element={<MenuDetails/>}/>
<Route exact path="/services" element={<AdditionalServices/>} />
<Route exact path="/terms" element={<TermsAndConditions/>} />
<Route exact path="/images" element={<CustomerShares/>}/>
<Route exact path="/inquires" element={<PartyInquires/>}/>

</Route>


<Route exact path="/contact" element={<ContactUs/>}/>
<Route  exact path="*" element={<NotFound/>}/>
<Route exact path="/menu/:id/booking" element={<BookMenu/>}/>

<Route exact path="/inquires/basket" element={<Basket/>}/>
<Route exact path="/inquires/basket-approved" element={<BasketSent/>}/>
<Route exact path="/vat" element={<Vat/>}/>
<Route exact path="/menu-booked" element={<MenuBooked/>}/>

<Route element={<PrivateRoutes/>}>
<Route exact path="/profile" element={<Profile/>}/>
<Route exact path="/profile/order" element={<OrderList/>}/>
<Route exact path="/profile/logout" element={<LoggingOut/>}/>
<Route exact path="/order-process/:id" element={<OrderProcess/>}/>
<Route exact path="/profile/charge" element={<ChargeWallet/>}/>
<Route path='/payment-success' element={<PaymentSuccess/>}/>

</Route>

</Route>



<Route exact path="/login" element={<Login/>}/>
<Route exact path="/register" element={<Register/>}/>
<Route exact path="/forgot" element={<ForgotPassword/>}/>
<Route exact path="/verify" element={<Verify/>}/> 
<Route exact path="/usercode" element={<UserCode/>}/>
<Route exact path="/new-password" element={<NewPassword/>}/> </Route>

      </Routes>
      </OrderContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
