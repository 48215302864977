


import '../Styles/Medyaf.css';
import React, {useState} from 'react';

import { HiShoppingCart } from "react-icons/hi";
import { useTranslation } from 'react-i18next';
import { BackgroundImage } from 'react-image-and-background-image-fade'

function MenuMeals(props) {
  const { t, i18n } = useTranslation();
    return (



  <div className=" col-sm-6 col-lg-4 productCenter">
    <div className="p-3 productItemBook">
    <div style={{display:"flex",justifyContent:"center"}}>
{/*<div className='menuBckg' style={{backgroundImage:`url(${props.image})`}}></div>
*/}
<BackgroundImage
        src={props.image}
       width="100%"
       lazyLoad
       className="menuBckg"
      />

</div>
<p className="productName" style={{marginBottom:"0"}}><span style={{color:"#e47100"}}>{t("mainMenu.no")} ({props.quantity}) -</span> {props.name}</p>

<p className="productDescription">{props.describe}</p>

  </div></div>
   

    );
}

export default MenuMeals;
