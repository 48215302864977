


import '../Styles/Medyaf.css';
import React, {useState,useRef} from 'react';
import { use } from 'i18next';
import { useTranslation } from 'react-i18next';
import * as yup from "yup"
import {useFormik} from "formik";
import {CustomerInfo,updateCustomer} from "../api";
import { useNavigate } from 'react-router-dom';
function YourProfile() {
    const [update,setUpdate]=useState(false);
    const { t, i18n } = useTranslation();
     const history=useNavigate();
    const [msgAr, setMsgAr]=useState();
    const [msgEn, setMsgEn]=useState();

    const [errorMsgModel, setErrorMsgModel]=useState(false);
    const name= localStorage.getItem("userName"); //saved when user logged-in
const phone=localStorage.getItem("userPhone");//saved when user logged-in


 


let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
 
const Schema= yup.object().shape({
phone: yup.string().matches(phoneRegEx,{message:t("validation.phone_not_valid")}).required(t("validation.phone_required")),
password: yup.string().min(6, t("validation.at_least")).required(t("validation.password_required")),
name: yup.string().required(t("validation.name_required"))



})

const formik = useFormik({
    initialValues:{
      name: name,
     phone: phone,

      password: '',
    
    },
    validationSchema: Schema,
    onSubmit,
    });
    const passRef=useRef();
    const phoneRef=useRef();
  
    const nameRef=useRef();


    
    async function onSubmit(event){

const values={
    name: nameRef.current.value,
    phone: phoneRef.current.value,

     password: passRef.current.value,

}
const token= localStorage.getItem("token");

        if(phone !== phoneRef.current.value){
            updateCustomer( values, token).then((response) => {
                console.log("new number",phoneRef.current.value);
                CustomerInfo(token).then((response) => {
                    localStorage.setItem("userName",response.data.data.name);
                    localStorage.setItem("userPhone",values.phone);
                    window.location.reload();
                
                }).catch(function (error) {
                    if (error.response.status === 401) {
          
                        localStorage.removeItem("token");
                        localStorage.removeItem("userPhone");
                        localStorage.removeItem("userName");
                        localStorage.removeItem("basket");
                        localStorage.setItem("location","myOrders");
                        history("/login")
                     }
                else if (error.response.status === 422) {
                          
                            setMsgAr("هذا الرقم مسجل من قبل");
                            setMsgEn("This phone already exists");
                            setErrorMsgModel(true);

                        }   else {
                             console.log(error)
                        }
                    
                    }).catch(function (error) {
                        if (error.response.status === 401) {
              
                            localStorage.removeItem("token");
                            localStorage.removeItem("userPhone");
                            localStorage.removeItem("userName");
                            localStorage.removeItem("basket");
                            localStorage.setItem("location","myOrders");
                            history("/login")
                         }})

            })

    }

    else {
        let valuesUpdated  = {
            name: nameRef.current.value,
 

     password: passRef.current.value,
        };
        updateCustomer( valuesUpdated, token).then((response) => {
            console.log("new name",response);
            CustomerInfo(token).then((response) => {
             
                localStorage.setItem("userName",response.data.data.name)

              window.location.reload();
            
            }).catch(function (error) {
                    if (error.response.status === 422) {
                      
                        setMsgAr("هذا الرقم مسجل من قبل");
                        setMsgEn("This phone already exists");
                        setErrorMsgModel(true);

                    }   else {
                         console.log(error)
                    }
                
                })

        })

    }

}
function changeName(){
    const token= localStorage.getItem("token");
    updateCustomer( nameRef.current.value, token).then((response) => {
     
        CustomerInfo(token).then((response) => {
         
            localStorage.setItem("userName",response.data.data.name)

          window.location.reload();
        
        })

    })

}

 
    return (
<div style={{marginBottom:"5em"}}  className='profileDetail'>
    <div className='detailDiv'>
<p className='profileTitle'>{t("profile.my_profile")}</p>
{ !update?
<div className='contain'>
<div className='row'>


{
<div className='userDetails col-6'>
  {/*  <button className='editP' onClick={()=>setUpdateN(true)}>
<BiEdit size={25} />
</button>*/}

<p className='detLabel'>{t("profile.yourName")}:</p></div>
/*
: 

<div className='updateProfile' style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
<label>{t("profile.name")}</label>
   
<button className='editP' onClick={()=>setUpdateN(false)}>
<TiCancelOutline size={30} />
</button>
<input type='text'  value={formik.values.name} id="name" name="name"  ref={nameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.name && formik.touched.name?"invalid":null}/>
<span>{formik.errors.name && formik.touched.name?formik.errors.name:null}</span>
</div>*/
}


<div className='userDetails col-6'>
  
<p className='detValue'>{name}</p></div>
<div className='userDetails col-6'>
    
{/*<button className='editP'>
<BiEdit size={25} />
</button>*/}
<p className='detLabel'>{t("profile.yourPhone")}:</p></div>
<div className='userDetails col-6'>
<p className='detValue'>{phone}</p></div>


</div>
{/*
<button style={{marginTop:"1.5em"}} onClick={()=>setUpdate(true)} className='charge'>{t("profile.update")}</button>
*/}</div>:
<form className='updateProfile' onSubmit={formik.handleSubmit}>

    <label>{t("profile.name")}</label>
   
    <input type='text'  value={formik.values.name} id="name" name="name"  ref={nameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.name && formik.touched.name?"invalid":null}/>
<span>{formik.errors.name && formik.touched.name?formik.errors.name:null}</span>
    <label>{t("profile.phone")}</label>
   
   <input type='text'  value={formik.values.phone} id="phone" name="phone"  ref={phoneRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone?"invalid":null}/>
   <span >{formik.errors.phone && formik.touched.phone?formik.errors.phone:null}</span>
   <label>{t("profile.password")}</label>
   
   <input type='password'  value={formik.values.password}  name="password"  ref={passRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password?"invalid":null}/>
   <span>{formik.errors.password && formik.touched.password?formik.errors.password:null}</span>
   
   
{i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}
    <button type="submit" style={{marginTop:"40px"}}>{t("profile.save")}</button>
</form>


}
</div>
</div>

    );
}

export default YourProfile;
