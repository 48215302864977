


import '../Styles/Medyaf.css';
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import burger from "../burger.jpeg"
import { useTranslation } from 'react-i18next';


function DeleteItem(props) {
const [quantity,setQuantity]=useState(1);
const { t, i18n } = useTranslation();
const [cartItem,setCartItem]=useState([]);
const [product,setProduct]=useState([]);




 
//on confirming removing meal from cart this function calls handleClose function (in Basket component) then is closed
function handleDel(){

    props.handleClose(props.delId)
    
    props.setModal(false)
    
    }

    return (


        <div  className="foodModal">
          { console.log(cartItem)}
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"200px"}}>
          
            <div >
             <div style={{height:"20vh",flexDirection:"column",textAlign:"center",justifyContent:"space-around"}} className='modalBody'>
         <p className='cancelOrder'>
          {t("profile.delete_modal")}</p>
           <div className='modalBtns'>
           <button className='confirm' onClick={handleDel}>     {t("Login.confirm")}</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     {t("profile.cancel")}</button></div>
            </div>
          
     
            </div>
            <button className="close-modal" onClick={()=>props.setModal(false)}>
              <IoCloseSharp size={40}/>
            </button>
          </div>
        </div>
    

    );
}

export default DeleteItem;
 