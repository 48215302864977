
import '../Styles/Medyaf.css';
import React, {useState,useEffect} from 'react';
import MainMenuItems from '../Components/MainMenuItems';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import CarouselComp from '../Components/CarouselCom';
import { useTranslation } from 'react-i18next';
import { menus } from '../api'
import { useNavigate } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";

function MainMenu() {
    const { t, i18n } = useTranslation();
    const[mdiafMenus,setMdiafMenus]=useState([]);
 const history= useNavigate();
  const [loading,setLoading]=useState(true);

 
 //menu api call to fetch mediaf menus
  useEffect(() => {
   
    menus().then(response => {
     const menu=response.data.data.mdiafMenus;
     setMdiafMenus(menu);

console.log(response.data.data.mdiafMenus);

setLoading(false);
}).catch(function (error) {
  if(error.response.status === 422){
  history('/err');}
});



   },[]);
    return (
<div style={{position:"relative"}}>
{loading?  <div className='loader'>
       <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
            <div>
                
            <CarouselComp/>
            <div className="container-fluid fluid">
                        <div className="row">
                            <div className="hospitableInfo">
                             <h1>{t("mainMenu.hospitable")}</h1>
<p>{t("mainMenu.about_hospitable")}</p>                        </div>

                        </div></div>
    
            <div className="container-fluid fluid">
            
                   
                    <div style={{marginBottom:"5em"}} >
  <div className="row gy-5">

  {mdiafMenus && mdiafMenus.map((data,index)=>
     <MainMenuItems key={index} id={data.id} image={data.image} min={data.minPersonsNumber} price={data.pricePersonSAR} name={i18n.language=="ar"?data.arName:data.enName}/>
     
     ) 
}

  </div>
</div>
               
</div>    
           <Footer/>         </div>}</div>
    );
}

export default MainMenu;
