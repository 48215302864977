


import '../Styles/Medyaf.css';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { HiShoppingCart } from "react-icons/hi";
import { BackgroundImage } from 'react-image-and-background-image-fade'

import { BsPlusLg } from "react-icons/bs";
function PartyProduct(props) {
  const { t, i18n } = useTranslation();


 

  //on clicking add button modal for adding meals is opened (Modal component found in /Components) and data for that modal is set
  function handleOpen(){
   
    props.setModalData({
name: props.name,
describe: props.describe,
image:props.image,
price: props.price,
mealArName: props.arName,
mealEnName: props.enName,
mealArDescription: props.arDescribe,
mealEnDescription: props.enDescribe,
mealOriginalPrice: props.price,
id:props.id

    })
    props.setModal(true)
  }
    return (


  <div className="col-12">
   
    <div style={{maxWidth:"none",width:"100%"}} className="row productItem">
    
      <div  className='col-md-2 col-sm-4 colFlex  col-3'>
   
     {/* <div className='productImg' style={{backgroundImage:`url(${props.image})`}}></div>*/}
      <BackgroundImage
        src={props.image}
     
       lazyLoad
       className="productImg"
      />
</div>

<div className={i18n.language=="ar"? 'col-sm-5 colFlexAr  col-7': 'col-sm-5 colFlex col-7'}>
<p className="productName">{props.name}</p>
<p className="productDescription">{props.describe}</p>
</div>

<div className='col-md-5 col-sm-3 colFlexBtn col-2'>
<button className='productItemBtn' onClick={handleOpen}> <BsPlusLg/></button>
  </div>
  
  </div></div>

    );
}

export default PartyProduct;
