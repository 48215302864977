


import '../Styles/Medyaf.css';
import React, {useState,useEffect} from 'react';
import logo from "../Logo.svg"
import { FaUserAlt } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import {AiOutlineMenu} from "react-icons/ai";
import { LanguageContext } from '../Context/LanguageContext';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import saudi from "../Images/Login/Saudia.svg"
import logoAr from "../Images/logoAR3.svg"
import logoEn from "../Images/logoEn.svg"
import { Link } from 'react-router-dom';
function LoginNav() {
    const [toggle,setToggle]=useState(false);
    //const [languageState, SetLanguagState]= useState(language);
    const [lang,setLang]=useState();
    const { t, i18n } = useTranslation();

 
    const changeLanguage = (e) => {
        var language = e.target.value;
   
         i18n.changeLanguage(language);
         localStorage.setItem("language",JSON.stringify(language ));
         setLang(language);
        console.log(i18n.language);
       if(language==="en"){
       document.getElementById("root").style.direction="ltr";
     
       }
       else{
         document.getElementById("root").style.direction="rtl";
       }
       };
 
 
       useEffect(() => {

       const data=  JSON.parse(localStorage.getItem("language"));
         i18n.changeLanguage(data);
         setLang(data);
     
       
         if(i18n.language==="en"){
             document.getElementById("root").style.direction="ltr";
          
             }
             else if(i18n.language==="ar"){
               document.getElementById("root").style.direction="rtl";
             
             }
             else{
              document.getElementById("root").style.direction="rtl";
             }

             if(JSON.parse(localStorage.getItem("language"))==="ar"){
              document.title=" مضياف الحفلات الرومانسية"
          }else {
              document.title="Al Romansiah Medhyaf "
         
          }
                },[lang]);
 

    return (
        <div className='container-fluid fluid'>
<div style={{boxShadow:"none"}} id="topNav" className='topNav topNavLogin'>
    <div className='Loginlogo'>
    <Link   to="/"><img   src={i18n.language=="ar"?logoAr:logoEn}/></Link></div>

  
    <div className='navLinks '>
    
        </div>
    <div className='navContent'>
   
    <div className='mobileTogg' >
        <select value={lang}  onChange={(e)=>changeLanguage(e)}>
<option value={"en"}> English </option>
<option value={"ar"}> العربية </option>
    </select>
    </div>
  
</div>
  </div></div>
    );
}

export default LoginNav;
