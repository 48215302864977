


import '../Styles/Medyaf.css';
import React, {useState,useEffect} from 'react';
import Navbar from '../Components/Navbar';
import CarouselComp from '../Components/CarouselCom';
import PartyProduct from '../Components/PartyProduct';
import Modal from '../Components/Modal';
import SideNavLink from '../Components/SideNavLink';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { inquire} from '../api'
import Footer from '../Components/Footer';
import BeatLoader from "react-spinners/BeatLoader";
function PartyInquires() { 
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState();
  const { t, i18n } = useTranslation();
  const [category, setCategory] = useState(false);
  const [loading,setLoading]=useState(true);
  const [changeLoad,setChangeLoad]=useState(false);
  const [meals,setMeals]=useState(0); //active category index
  const [categoryMeal,setCategoryMeal]=useState([]);
  const [itemsNumber,setItemsNumber]=useState();



  //inquire api call to fetch party inquiry meals and categories
  //displayed meals are set according to current active category index (const [meals,setMeals])
  //rerenders when category is changed
  useEffect(() => {
    inquire().then(response => {
      const data=response.data.data.mdiafCategories;
      setCategory(data);
      console.log(data);
      setCategoryMeal(response.data.data.mdiafCategories[meals].mdiafMeals);
console.log(response.data.data.mdiafCategories[meals].mdiafMeals);

setLoading(false);
setChangeLoad(false);
    })

  

   },[meals]);


   //rerenders when modal for adding meals gets open/closed to update cart items number
   useEffect(() => {
    const data= JSON.parse(localStorage.getItem("basket"));
    if(data){

setItemsNumber(data.length);
    }
   },[modal]);

    return (
      <div>{loading?<div className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
<div>

{modal? (
        <Modal setModal={setModal}  modalData={modalData}/>
      ):null}

<CarouselComp/>
    
     

<div style={{marginBottom:"5em"}} className='container-fluid fluid'>
    <div className='bodyMargin2'   >
      <div  className="normalCategory2">
      <p className='productsTitle'>{t("party.categories")}</p>
<div className={i18n.language=="ar"?'sideNavAr':'sideNav'}>

{category && category.map((data,index)=>
     <SideNavLink setChangeLoad={setChangeLoad} style={i18n.language=="ar"?{borderRight:"5px solid #e47100", backgroundColor: "rgba(228, 113, 0, 0.07)"}:{borderLeft:"5px solid #e47100", backgroundColor: "rgba(228, 113, 0, 0.07)"}} index={index} meals={meals} setMeals={setMeals} key={index} id={data.id} image={data.image} name={i18n.language=="ar"?data.arName:data.enName}/>
   
     ) 
   
}

</div>
</div>
<div style={{width:"100%"}}>
<div className='topCategories2'>
 
<p className='productsTitle'>{t("mainMenu.categories")}</p>
<div className='mobileSideLink' >
{category && category.map((data,index)=>
     <SideNavLink setChangeLoad={setChangeLoad} style={{borderBottom:"5px solid #e47100", backgroundColor: "rgba(228, 113, 0, 0.07)"}} index={index} meals={meals} setMeals={setMeals} key={index} id={data.id} image={data.image} name={i18n.language=="ar"?data.arName:data.enName}/>
     
     ) 
}

</div>

</div>
<div >
<div  className='container-fluid '>
<div className='scrollTitle'>
<p className='productsTitle'>{t("party.inquireParagraph2")}</p>
<Link to="/inquires/basket">  
      <button className={itemsNumber>0?'goBasket count':'goBasket'} data-count=  {itemsNumber} >{t("party.goToBasket")}</button>
      </Link>
</div></div>
<div style={changeLoad?{display:"flex",justifyContent:"center"}:{display:"flex"}}>
  
<div className='products' >

<div  className='container-fluid '>

<div style={{marginBottom:"100px"}} className="row gy-4">


{!changeLoad? categoryMeal && categoryMeal.map((data,index)=>
     <PartyProduct price={data.priceSAR} setModalData={setModalData} setModal={setModal} key={index} id={data.id} image={data.image} name={i18n.language=="ar"?data.arFullName:data.fullName} enName={data.fullName} arName={data.arFullName} describe={i18n.language=="ar"?data.mdiafProduct.arDescription:data.mdiafProduct.enDescription} arDescribe={data.mdiafProduct.arDescription} enDescribe={data.mdiafProduct.enDescription}/>
     
     ) :


<div className='formBottom partyLoader' style={{justifyContent:"center"}}>
<div className='loaderDiv'>

<BeatLoader color={'#E47100'} loading={changeLoad}  size={30} /></div></div>}

</div>
  </div></div>
  {/*
  <div style={i18n.language=="ar"?{marginLeft:"1.5em",display:"inline-block"}:{marginRight:"3em",display:"inline-block"}} >
  <div className='total'>
    <p className='totalTitle'>  {t("party.productNum")}</p>
    <p className='totalNum'>    {itemsNumber} </p>
    <Link to="/party/basket">   <button> {t("party.goToBasket")}</button></Link>
    </div></div>

*/} 
  </div>
 
  </div> 



</div>
  </div></div>
  <Footer/>
  </div>} </div>
    );
}

export default PartyInquires;
