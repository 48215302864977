
 
import '../Styles/Medyaf.css';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCash } from 'react-icons/hi';
import { BackgroundImage } from 'react-image-and-background-image-fade'

function Services(props) {
  const { t, i18n } = useTranslation();
    return (
   
 
    <div className="col-md-4 col-sm-6">
        <div className='menuContainer'>
      <div className=" additional">
     {/* <div className='serviceImage' style={{backgroundImage:`url(${props.image})`}}>
  
    </div>*/}
 <BackgroundImage
        src={props.image}
       width="100%"
       lazyLoad
       className="serviceImage"
      />

      <div>
      
     
      </div>
      
      <div className='paddingService'>
        <div  style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
        <p className='serviceName' style={{color:"#633235"}}>{props.name}</p></div>
      <div className='details'>
      <p className='serviceDetail'><HiOutlineCash size={20} className={i18n.language=="ar"?'mmIcon':'mmIconsAr'}/>  {props.price}{t("nav.SAR")}</p>
     
      <p className='serviceDetail'>{props.describe}</p></div>
 
      </div>
      </div></div>
    </div>
   
 
    );
}

export default Services;
