


import '../Styles/Medyaf.css';
import React, {useState,useEffect} from 'react';

import burger from "../burger.jpeg"

import Navbar from '../Components/Navbar';
import CarouselComp from '../Components/CarouselCom';
import { Link } from 'react-router-dom';
import { images } from '../api'
import CustomerImages from '../Components/CustomerImage';
import Footer from '../Components/Footer';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from 'react-i18next';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BackgroundImage } from 'react-image-and-background-image-fade'

// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "swiper/css/pagination";

// import required modules
import { FreeMode, Navigation, Thumbs ,Autoplay,Pagination } from "swiper";

function CustomerShares() {
const[index,setIndex]=useState(0);
const [isOpen,setIsOpen]=useState(false);
const { t, i18n } = useTranslation();
  const[image,setImage]=useState([]);
  const [lang,setLang]=useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [loading,setLoading]=useState(true);
const crsl=[];
 

{
image.map((data)=>{
  crsl.push(
{
image:data.image,
caption:"test"

}

  )

}

)

}
  
//images api call for fetching customers' images to display in slider
  useEffect(() => {
    images().then(response => {
     const data=response.data.data.mdiafImages;
     setImage(data);
  

  setLoading(false);
})
  
  

   },[]);

  //for rerendering slider direction on changing language
   useEffect(() => {
   
if(!loading){
  setLang(!lang)

}
  

   },[i18n.language]);
  
   
    return (
      <div>{loading?<div className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
<div>
{isOpen ? 
                                <Lightbox
                                    mainSrc={image[index].image}
                                 
                                    nextSrc={image[(index + 1) % image.length].image}
                                    prevSrc={image[(index + image.length - 1) % image.length].image}
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setIndex((index + image.length - 1) % image.length)
                                      
                                    }
                                    onMoveNextRequest={() =>
                                      setIndex((index + 1) % image.length)
                                      
                                    }
                                />
                            :null}
<CarouselComp/>


                        <div className='customerShares container-fluid fluid' style={{marginTop:"3em"}}>
                      
                      {/*
                        <Carousel autoPlay  interval="9000" infiniteLoop={true} centerMode={false} stopOnHover={false} showStatus={false}>

                          {image && image.map((data,index)=>
                          <div key={index}>
                       <img src={data.image}/>
                         
<div  className='crslBackground2' style={{ backgroundImage: `url(${data.image})` }}>
                     </div> 
                     </div>      
                          )}
                    
                          </Carousel>*/}

<div>
<Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#E47100",
        }}
        loop={true}
        autoplay={false}
      
       
        key={lang}
        navigation={true}
        spaceBetween={30}
        pagination={{
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 4
        }}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs,Autoplay,Pagination]}
       
        className={i18n.language=="ar"?"mySwiper2 btnsAr":"mySwiper2 btnsEn"}
      >
       
        {image && image.map((data,index)=>
                          <div key={index}>
                     <SwiperSlide>
    {/*                     
<div  className='crslBackground2' style={{ backgroundImage: `url(${data.image})` }}>
        </div> */}  
                     <BackgroundImage
        src={data.image}
     
       lazyLoad
       className="crslBackground2"
      />     
                     
                      </SwiperSlide>
                     </div>      
                          )}
                       
    
      </Swiper>
     
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        autoplay={false}
     
        dir={i18n.language=="ar"?"rtl":"ltr"}
        key={i18n.language}
        spaceBetween={10}
        slidesPerView={5}
        freeMode={true}
     
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs,Autoplay,Pagination]}
        className="mySwiper"
      >
      
        {image && image.map((data,index)=>
                          <div key={index}>
                    
                    <SwiperSlide>    
                      {/*     
<div  className='crslBackground2Thumbs' style={{ backgroundImage: `url(${data.image})` }}>
                     </div> 
        */}

<BackgroundImage
        src={data.image}
     
       lazyLoad
       className="crslBackground2Thumbs"
      />     
                     </SwiperSlide>

                     </div>      
                          )}
                          
       
      </Swiper>
  </div>

</div>
<div style={{marginTop:"80px"}} className='container'>
    
 

    
  </div>
  <Footer/>
  </div>} </div>

    );
}

export default CustomerShares;
