
import '../Styles/Medyaf.css';
import React, {useState,useEffect,useRef,useContext} from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../Components/Footer';
import { useParams,generatePath } from 'react-router-dom';
import { singleMenu ,book,services} from '../api'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { OrderContext } from '../Context/OrderContext';
import { useNavigate } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
import * as yup from "yup"
import {useFormik} from "formik"; 
 
function BookMenu() {
    const{setOrder}=useContext(OrderContext);
    var parseISO = require('date-fns/parseISO')
    const history= useNavigate();
    const[service,Setservice]=useState([]);

    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const [paymentLoad,setPaymentLoad]=useState(false);
    const [pricePerson,setPricePerson]=useState(false);

    const[menuCategories,setMenuCategories]=useState([]);
    const[date,setDate]=useState(new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate());
    const[time,setTime]=useState();
    const[minPersonsNumber,setMinPersonsNumber]=useState(0);
    const[maxPersonsNumber,setMaxPersonsNumber]=useState(0);
    const[startDate,setStartDate]=useState(new Date().setHours(new Date().getHours() + 2));
    const[minTime,setMinTime]=useState(new Date().setHours(new Date().getHours() + 2));
    const [ selectedOption,setSelectedOption]=useState();
    const [ selectedRadio,setSelectedRadio]=useState(true);
    const [loading,setLoading]=useState(true);
    const [servicesPrices,setServicesPrices]=useState(0);
    const [chosenServices,setChosenServices]=useState([]);

    let current = new Date();
    let d = current.getDate();
    let m = current.getMonth() + 1;
    let y = current.getFullYear();
    const numberRef= useRef();
    const addressRef= useRef(); 
    const noteRef= useRef();
    const [msgAr, setMsgAr]=useState();
    const [priceSinglePerson,setPriceSinglePerson]=useState(0);
    const [priceDefault,setPriceDefault]=useState(0);

const [msgEn, setMsgEn]=useState();

const [errorMsgModel, setErrorMsgModel]=useState(false);
let numberRegx = /^[0-9]+$/;

var checkList;


const Schema= yup.object().shape(
    
    
    {
    minNum: yup.number().required(("validation.num")).min(minPersonsNumber,("mainMenu.minimum")).max(maxPersonsNumber,("mainMenu.maximum")),
    address: yup.string().required(("validation.address")),
    note: yup.string().required(("validation.notes_required")),
  
     
    }
    
    )
    const formik = useFormik({
        initialValues:{
            minNum:"",
            address:"",
            note:"",
        },
        validationSchema: Schema,
      
        
        });
    
        //set default selected payment option
        //check if current menu id is = the one stored in localStorage ()=> if true values of booking form are filled with previously filled data
      //if not the same, previously stored booking data is removed from the localStorage
      //singleMenu api call takes menu id from url to fetch menu data
      //default picked date and time are set
    useEffect(() => {
        setSelectedOption("cash")
       
      const prevIndex=  JSON.parse(localStorage.getItem("bookIndex"));
      const prevData=  JSON.parse(localStorage.getItem("bookInfo"));
   
      
    
  
      if(prevIndex && prevData){

if(prevIndex==id){
formik.values.minNum=prevData.personsNumber;
formik.values.note= prevData.description;
formik.values.address=prevData.address;
setSelectedRadio(prevData.paymentMethod);
setStartDate(new Date(prevData.date))
setPricePerson(prevData.pricePerson)
setServicesPrices(prevData.servicesPrices)
setChosenServices(prevData.chosenServices)

handleDateAndTime(new Date(prevData.date))


if(prevData.paymentMethod==true){
  
        setSelectedOption("cash")

} 
else{

    setSelectedOption("tap")
}

}

else{
    localStorage.removeItem("bookInfo");
    if(d<10){
        d = '0'+d;
    }
    if(m<10){
        m = '0'+m;
    }
    let selectedDate = y + '-' + m + '-' + d;
    setDate(selectedDate);
    let hh = current.getHours()+2;
    let mm = current.getMinutes();

    if(hh<10){
        hh = '0'+hh;
    }
    if(mm<10){
        mm = '0'+mm;
    }
    let selectedTime = hh + ':' + mm ;
setTime(selectedTime)

  
}

      }
      else{
        if(d<10){
            d = '0'+d;
        }
        if(m<10){
            m = '0'+m;
        }
        let selectedDate = y + '-' + m + '-' + d;
        setDate(selectedDate);
        let hh = current.getHours()+2;
        let mm = current.getMinutes();
    
        if(hh<10){
            hh = '0'+hh;
        }
        if(mm<10){
            mm = '0'+mm;
        }
        let selectedTime = hh + ':' + mm ;
    setTime(selectedTime)
    
      }
      services().then(response => {
        const data=response.data.data.mdiafServices;
        Setservice(data);
   
     })
     
      
     
      
  
        singleMenu(id).then(response => {
         const data=response.data.data;
         setMenuCategories(data);
    
         setMinPersonsNumber(response.data.data.minPersonsNumber);
         if( !(prevIndex && prevData && prevIndex==id) ){
  
         setPricePerson(response.data.data.pricePersonSAR*response.data.data.minPersonsNumber)          
        }
         setPriceDefault(response.data.data.pricePersonSAR*response.data.data.minPersonsNumber)
         setPriceSinglePerson(response.data.data.pricePersonSAR)
         setMaxPersonsNumber( response.data.data.maxPersonsNumber);
         setLoading(false); 
        
       })
    
       },[]);



    //sets selected date (date + time)
//then sets selected time and date sperately
    const   handleChangeTime = (date) => {

    if (date=== new Date().toLocaleDateString()) {
      
        setStartDate(date);
        setMinTime (new Date().setHours(new Date().getHours() + 2))
     
    } else { 
       
            setStartDate(date);
            setMinTime( new Date(new Date().setHours(9, 30)));

     
    }

    

        handleDateAndTime(date)
};
function handleDateAndTime(date){

    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    if(d<10){
        d = '0'+d;
    }
    if(m<10){
        m = '0'+m;
    }
    let selectedDate = y + '-' + m + '-' + d;

    let hh = date.getHours();
    let mm = date.getMinutes();

    if(hh<10){
        hh = '0'+hh;
    }
    if(mm<10){
        mm = '0'+mm;
    }

    let selectedTime = hh + ':' + mm ;

   
        setDate(selectedDate);
        setTime( selectedTime);
}

/*
const handleOptionChange = (changeEvent) => {
    setSelectedOption( changeEvent.target.value);
    console.log(changeEvent.target.value)
     if((changeEvent.target.value)=="cash"){

setSelectedRadio(true)
     }
     else{
        setSelectedRadio(false)

     }
        
  
    }*/


    //on confirming booking orderSubmit function is called taking the object "values" as argument
    function handleSubmit(e){

        e.preventDefault();
        setPaymentLoad(true);
      /*  var arr=[]
var elements = document.getElementsByClassName('checkSerivce');
for(var i=0; i<elements.length; i++) { 
  if(elements[i].checked){
 
arr.push(elements[i].value)

}
}*/
const services = chosenServices.map(obj => obj.id);


        let values = {
            mdiafMenuId: parseInt(id),
            personsNumber: parseInt(numberRef.current.value),
            paymentMethod: "cash",
            description: noteRef.current.value,
            address: addressRef.current.value,
            date: date,
            time: time,
            services:services
         
        };
      

       orderSubmit(values);
    }


  //if user is not logged-in all user input is stored in localStorage and user is redirected to /login (Login component in /medyaf)  
/*else book api call takes user token and the object "values" (from orderSubmit fucntion call) 
then user previously-stored data is removed and user is redirected to whatsapp */
    const  orderSubmit = (values) => {
        setErrorMsgModel(false)
const token=localStorage.getItem("token");


if(!token){


 

const data={
 
    personsNumber: numberRef.current.value,
    paymentMethod: "cash",
    description: noteRef.current.value,
    address: addressRef.current.value,
date: startDate,
pricePerson: pricePerson,
servicesPrices: servicesPrices,
chosenServices: chosenServices,
}

    localStorage.setItem("bookIndex",JSON.stringify(id));
    localStorage.setItem("bookInfo",JSON.stringify(data));
    localStorage.setItem("location","book");
    history("/login")
}



book(values,token).then((response) => {
    window.spix('event','purchase');
    
    localStorage.removeItem("bookIndex");
    localStorage.removeItem("bookInfo");

   
    localStorage.setItem("orderId", response.data.data.orderId);

 if (selectedOption === "tap") {
        if (response.data.data.url === null) {
console.log("tap");
//localStorage.setItem("menuRoute", "false");

        }
        else {
            
           window.location.href = response.data.data.url
        }
    }
    else {
     
        setOrder(0);
        history(`/menu-booked`);
    }

}).catch(error => {
    if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("userPhone");
        localStorage.removeItem("userName");
        localStorage.removeItem("basket");
      
const data={
 
    personsNumber: numberRef.current.value,
    paymentMethod: "cash",
    description: noteRef.current.value,
    address: addressRef.current.value,
date: startDate,
pricePerson: pricePerson,
servicesPrices: servicesPrices,
chosenServices: chosenServices,
}

    localStorage.setItem("bookIndex",JSON.stringify(id));
    localStorage.setItem("bookInfo",JSON.stringify(data));
    localStorage.setItem("location","book");
    
        history("/login")

    } else {
        setPaymentLoad(false);
        getError(error.response.data.error)
    }

});





    }



//called in orderSubmit function
//handles errors
const  getError = (msg) => {
    if (msg === "branch not support branch pick up") {
     
        setMsgAr("الفرع لا يدعم خدمة توصيل الطالبات");
            setMsgEn( "branch not support branch pick up");
            setErrorMsgModel(true);
       
    } else if (msg === "branch not support delivery") {
      
            setMsgAr( "الفرع لا يدعم خدمة توصيل المنزل");
            setMsgEn( "branch not support delivery");
            setErrorMsgModel( true);
       
    
    } else if (msg === "branch not exist") {
     
            setMsgAr( "الفرع خارج نطاق الخدمة");
            setMsgEn( "branch not exist");
            setErrorMsgModel( true);
      
    } else if (msg === "branch not active") {
       
            setMsgAr( "الفرع غير متاح حاليا");
            setMsgEn( "branch not active");
            setErrorMsgModel( true);

    } else if (msg === "branch not open") {
     
            setMsgAr("الفرع مغلق الأن");
            setMsgEn( "branch not open");
            setErrorMsgModel( true);
      
  
    } else if (msg === "meal not exist") {
   
            setMsgAr("الوجبة غير متاحة حاليا");
            setMsgEn( "meal not exist");
            setErrorMsgModel( true);
  
    } else if (msg === "meal not active") {
    
        setMsgAr("الوجبة غير متاحة حاليا");
        setMsgEn(  "meal not active");
        setErrorMsgModel( true);
  
    } else if (msg === "product not delivery") {
      
          setMsgAr( "الوجبة غير متاحة للتوصيل");
            setMsgEn( "product not delivery");
            setErrorMsgModel( true);
     
    } else if (msg === "additional not delivery") {
 
          setMsgAr( "الإضافات غير متاحة للتوصيل");
           setMsgEn("additional not delivery");
              setErrorMsgModel( true);
         
    
    } else if (msg === "extra not exist") {
      
          setMsgAr( "الزيادات غير متاحة");
           setMsgEn("extra not exist");
              setErrorMsgModel( true);
         
   
    } else if (msg === "extra not belong to product") {
    
          setMsgAr( "الزيادات غير متوفرة مع الوجبة");
           setMsgEn("extra not belong to product");
              setErrorMsgModel( true);
      
   
    } else if (msg === "The selected code is invalid.") {
        
          setMsgAr( "الكود غير صحيح");
           setMsgEn("The selected code is invalid.");
              setErrorMsgModel( true);
         
   
    } else if (msg === "address not valid") {
        
          setMsgAr( "العنوان غير صحيح");
           setMsgEn("address not valid");
              setErrorMsgModel( true);
         
     
    }
    else if (msg === "time not in branch time") {
        
          setMsgAr( "الفرع مغلق في الوقت المحدد");
           setMsgEn("time not in branch time");
              setErrorMsgModel( true);
         
     
    }
    else if (msg === "branch not support fast delivery") {
        
          setMsgAr( "الفرع لا يوفر التوصيل السريع");
           setMsgEn("branch not support fast delivery");
              setErrorMsgModel( true);
         
    }
    else if (msg === "branch busy") {
        
          setMsgAr( "الفرع مشغول، تقدر تطلب بعد الزحام.");
           setMsgEn("This branch is currently busy. You can order for later when branch is not busy.");
              setErrorMsgModel( true);
         
  
    }
    else if (msg === "branch not support ordinary delivery") {
        
          setMsgAr( "الفرع لا يوفر التوصيل العادي");
           setMsgEn("branch not support ordinary delivery");
              setErrorMsgModel( true);
         
   
    } else {
        
          setMsgAr( "حدث خطأغير متوقع");
           setMsgEn("something went wrong");
              setErrorMsgModel( true);
         
    
    }


};




function dropDown(){
    checkList = document.getElementById('list1');
    if (checkList.classList.contains('visible')){
    checkList.classList.remove('visible');}
  else{
    checkList.classList.add('visible');

    if(chosenServices.length>0){
     chosenServices.forEach(obj => {
            const checkbox = document.getElementById(`service${obj.id}`);
            if (checkbox) {
              checkbox.checked = true;
            }
          });}

}




}

function handleChangeNum(e){
    formik.handleChange(e);
   

    if(minPersonsNumber<= e.target.value &&  e.target.value <= maxPersonsNumber){
        setPricePerson( parseFloat(((e.target.value * priceSinglePerson)+ servicesPrices).toFixed(2)) )
    }
    
    else{
return setPricePerson(priceDefault + servicesPrices)    }
}

function handlePrice(e,price,service,id){
    if(e.target.checked){setPricePerson(prev => prev + price )
 
        setServicesPrices( prev => prev+price)

        setChosenServices([...chosenServices, {name:service,id:id}]);

    }

    else{setPricePerson(prev => prev - price )
        setServicesPrices( prev => prev - price)
        setChosenServices(chosenServices.filter((item) => item.id !== id));


    }
}
    return (
        <div>{loading?<div className='loader'>
        <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
        <div>
<div className='centerForm container-fluid fluid'>
   
   <form className=' bookForm' onSubmit={(e)=>handleSubmit(e)}>
   {paymentLoad?

<div style={{height:"85vh"}} className='loader'>

<BeatLoader color={'#E47100'} size={30} /></div>:null}

<div style={paymentLoad?{display:"none"}:null}>
       <div className='row'>
<div style={{padding:"3em"}} className='col-lg-6 alignBookTop'>
<p style={{marginBottom:"1em"}} className="bookName"> {i18n.language=="ar"?menuCategories.arName:menuCategories.enName}</p>
<div className='menuImageDiv'>
<div className={i18n.language=="ar"?'bookImage zoomAr zoom':'bookImage zoom'} style={{backgroundImage:`url(${menuCategories.image})`}}></div>
</div>

<p style={{marginBottom:"0"}} className="bookPrice"> {t("mainMenu.min_persons")} {menuCategories.minPersonsNumber}   {t("mainMenu.person")}</p>
</div>
<div className={i18n.language=="ar"?'col-lg-6 bookingForm bookingAr':'col-lg-6 bookingForm'} >
   
<div className='container'>
    <div className='row'>
    <div className='col-6 '>
    <label> {t("mainMenu.num_persons")} </label>
    <input type="number" id="persons" required  ref={numberRef} min={minPersonsNumber} max={maxPersonsNumber} value={formik.values.minNum}  name="minNum"   onChange={ (e)=>handleChangeNum(e)} onBlur={formik.handleBlur}  className={formik.errors.minNum && formik.touched.minNum?"invalid":null} />
    <span className='invalidSpan'>{formik.errors.minNum && formik.touched.minNum?t(`${formik.errors.minNum}`):null}</span>
    </div>
 
    <div className='col-6'>
    <label>{t("mainMenu.chooseDate")} </label>

    <DatePicker
    required
                                          selected={startDate}
                                            showTimeSelect
                                            dateFormat=" h:mm ,yyyy/MM/dd"
                                            minTime={minTime}
                                            maxTime={new Date(new Date().setHours(23, 59))}
                                            minDate={new Date()}
                                            timeFormat="HH:mm"
                                            onChange={handleChangeTime}
                                        />
                                
    </div>
  

    <div className='col-12'>
    <label> {t("mainMenu.enterAddress")}</label>
    <textarea  id="address" required  ref={addressRef} value={formik.values.address}  name="address"   onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.address && formik.touched.address?"invalid":null} />
    <span className='invalidSpan'>{formik.errors.address && formik.touched.address?t(`${formik.errors.address}`):null}</span>
  
    </div>

    <div className='col-12'>
    <label>{t("mainMenu.enter_your_notes")}</label>
    <textarea  id="notes" value={formik.values.note}  name="note"  onChange={formik.handleChange} required={true}   ref={noteRef} onBlur={formik.handleBlur}  className={formik.errors.note && formik.touched.note?"invalid":null}/>
    <span className='invalidSpan'>{formik.errors.note && formik.touched.note?t(`${formik.errors.note}`):null}</span>
  
</div>

<div id="list1" className="dropdown-check-list" tabindex="100">
  <span onClick={dropDown} className="anchor">{chosenServices.length>0? chosenServices.map((obj,index,array) => 
      <>
 { index<array.length-1?
      obj.name + " , ":obj.name   }  </>
      ) : t("mainMenu.choose_services")}</span>
  <ul class="items"> 
  {service && service.map((data,index)=>
      <li key={index}>
          <div>

          <input type="checkbox" id={`service${data.id}`} onChange={(e)=>handlePrice(e,data.price,i18n.language=="ar"?data.arName:data.enName,data.id)} className='checkSerivce'  value={data.arName}/><span> {i18n.language=="ar"?data.arName:data.enName}</span>
         
          </div>
<p>{data.price} {t("nav.SAR")}</p>
           </li>
    
     )
}
 
  </ul>
</div>
    <div className='col-12 '>
        {/*
        <div className='row'>
    <p className='payment'>{t("mainMenu.paymentMethod")} </p>
    <div className='paymentMethod'>
        <div className='col'>
        <input value="cash" name="payment" id="cash" type="radio" onChange={handleOptionChange} defaultChecked={selectedRadio}/>
    <label className='paymentLabel'>{t("mainMenu.arrivalPayment")}</label>
   </div>

    <div className='col'>
    <input value="tap" name="payment" id="tap" type="radio"  onChange={handleOptionChange} defaultChecked={!selectedRadio}/>
    <label className='paymentLabel'>{t("mainMenu.creditCardPayment")}</label>
  
   </div>
    
    </div></div>*/}
    
    </div>
    </div>
</div>
</div>
       </div>
       <div className='bookErr'>
       {i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}</div>
      
           
          
{/*<button type='submit'>{t("mainMenu.confirm")}</button>*/}

 <div className='formBottom'>
<div>
<p>{t("mainMenu.total_price")}</p>  
<p> {pricePerson}  {t("nav.SAR")}</p>  </div>
<button type='submit'>{t("mainMenu.book")}</button> </div>
</div>
       </form> 
     
  </div>
    <Footer/>
    </div>}</div>
    );
}

export default BookMenu;
