


import '../Styles/Medyaf.css';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate,useLocation } from "react-router-dom";
import logo from "../Images/MainLogo.svg"
function Logout() {
    const { t, i18n } = useTranslation();
const history =useNavigate();
function handleLogout(){
   

    localStorage.removeItem("token");
    localStorage.removeItem("userPhone");
    localStorage.removeItem("userName");
    localStorage.removeItem("basket");
    localStorage.setItem("location","profile");
    history("/");
  
}  


    return (
<div style={{marginBottom:"5em"}}  className='profileDetail'>
    <div className='detailDiv'>
<div className='logoutContain'>
    <img src={logo}/>
   <p className='logout'>{t("profile.logout_modal")} </p>
   <button onClick={handleLogout} className='charge'>{t("profile.log_out")} </button>
    </div>
</div>
</div>

    );
}

export default Logout;
