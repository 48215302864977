


import '../Styles/Medyaf.css';
import React, {useState} from 'react';
import { SRLWrapper } from "simple-react-lightbox";
import burger from "../burger.jpeg"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Footer from './Footer';
import err from "../Images/404.png"
import { useTranslation } from 'react-i18next';

function NotFound() {
  const { t, i18n } = useTranslation();


    return (

      <div className='flexBetween'>

<div style={{marginTop:"1.5em",marginBottom:"2em",minHeight:"32.5vh"}} className='container-fluid fluid notFoundCountainer orderSuccess'>
    
        <div className='notFound' style={{backgroundImage:`url(${err})`}}>
        <p>{t("Login.notFound")}</p>


        </div>
  </div>
      <Footer/>
      </div>
    );
}

export default NotFound;
