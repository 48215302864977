


import '../Styles/Medyaf.css';
import React, {useState,useEffect,useRef} from 'react';
import { BsImages } from "react-icons/bs";
import Navbar from '../Components/Navbar';
import CarouselComp from '../Components/CarouselCom';
import { HiArrowRight } from "react-icons/hi";
import { Link } from 'react-router-dom';
import BasketItems from '../Components/BasketItems'
import { useTranslation } from 'react-i18next';
import * as yup from "yup"
import {useFormik} from "formik";
import {sendBasket} from "../api";
import Footer from '../Components/Footer';
import { useNavigate } from 'react-router-dom';
import { BiBasket } from "react-icons/bi";
import { HiShoppingCart } from 'react-icons/hi';
import logo from "../Images/MainLogo.svg"
import DeleteItem from '../Components/DeleteItem';
function Basket() {
  const { t, i18n } = useTranslation();
const [basket,setBasket]=useState([]);
const [loading,setLoading]=useState(true);
const [total,setTotal]=useState(true);
const [changeQuan,setChangeQuan]=useState(false);
const topRef = useRef()
const [modal,setModal]=useState(false);
const [delId,setDelId]=useState();
const history= useNavigate();

 

//sets basket data
//calculates basket total price
//rerenders on changing quantity of meals or removing an item from basket 
  useEffect(() => {
 
    const data= localStorage.getItem("basket");
    if(data){
      setBasket(JSON.parse(data)); 
    
    }
 var total=0
    JSON.parse(data) && JSON.parse(data).map((data)=>
    {
total=total+ (data.mealOriginalPrice* data.quantity);
setTotal(total);
    }
  

 
    
    )

    setLoading(false);


   

   },[setBasket,changeQuan]);



   const Schema= yup.object().shape({
    address: yup.string().required(("basket.enterAddress")),
    notes: yup.string().required(("basket.enter_your_notes")),
    
    })

    const formik = useFormik({
      initialValues:{
        address:"",
        notes:"",
      },
      validationSchema: Schema,
      onSubmit
      
      });
      const noteRef=useRef();
    const addressRef=useRef();





//on confirming order
    //if user is not logged-in user is redirected to /login

//sendBasket api call takes object "data" and user token user is then redirected to /inquires/basket-approved (BasketSent component found in /Medyaf) for dislpaying success

    async function onSubmit(event){

 
    const token=localStorage.getItem("token");

if(!token){
  localStorage.setItem("location","basket");
    history("/login")
}

      const data={
   
        notes: noteRef.current.value,
        address: addressRef.current.value,
        meals: JSON.stringify(basket)
      } 

      let arr=[]

      basket.map((data)=>
arr.push(
  `${data.mealArName + " "+data.quantity+"x"+" "}`


)
)

      sendBasket(data,token).then((response) => {
        localStorage.setItem("inquireSuccessMsg", "true");
        localStorage.setItem("basket", "[]");
      //  history("/inquires/basket-approved");
      window.location.href = `https://api.whatsapp.com/send/?phone=966532222067&text=${"  عنوان الطلب:"}+${ addressRef.current.value}%0A${" ملحظات العميل:"}+${ noteRef.current.value}%0A${"السلة:"}+${arr.toString()}`
   
       
    }).catch(error => {
        if (error.response.status === 401) {
      
           localStorage.removeItem("token");
           localStorage.removeItem("userPhone");
           localStorage.removeItem("userName");
           localStorage.removeItem("basket");
           localStorage.setItem("location","basket");
           history("/login")
        } else {
          
            console.log(error);
        }
    });


    }   



//on confirming removing item from basket in DeleteItem Modal
//takes meal id and filters basket to remove meal with that id then localstorage item "basket" is set to new value


function handleClose(id){
  const data= JSON.parse(localStorage.getItem("basket"));
  const result = data.filter(data => data.id!==id);
  setBasket(result);
  localStorage.setItem("basket", JSON.stringify(result));


}

    return (
       
    
     
 
<div >
{modal? (
        <DeleteItem setModal={setModal} handleClose={handleClose} delId={delId}/>
      ):null}

      {/*  <div className='bTop' >
      
    

  <p>&#160;</p>
  <div className={i18n.language=="ar"?'backAr' : 'back'}>

   <Link to="/party"> <button  className="ciBackbtn"><HiArrowRight size={30}/></button></Link>
   <p> {t("basket.backToMenu")} </p>
   </div>
  
   {  !basket.length==0?
  <p style={{color:"#E47100",fontSize:"20px"}}> {t("basket.inquireParagraph")}</p>
  :
  <div>
  <p style={{color:"#E47100",fontSize:"20px"}}>{t("basket.isEmpty")} </p>

  <Link to="/party"><button className='addMore'> {t("party.add_basket")} <HiShoppingCart/></button></Link>
  </div>
  }


</div>*/}
{!basket.length==0?
<div >
  <div className=' container-fluid fluid'>
 <div className='basketTop ' >
 <p > {t("basket.inquireParagraph")}</p>
 <Link to="/inquires"> <button >{t("basket.backToMenu")}</button></Link>

 
   </div></div>
   <div style={{marginTop:"20px"}} className='container-fluid fluid'>



<div className='row gy-4'>
{


basket && basket.map((data,index)=>
     <BasketItems setModal={setModal} setDelId={setDelId} changeQuan={changeQuan} setChangeQuan={setChangeQuan} handleClose={handleClose} price={data.mealOriginalPrice} key={index} id={data.id} image={data.mealImage} name={i18n.language=="ar"?data.mealArName:data.mealEnName} describe={i18n.language=="ar"?data.mealArDescription:data.mealEnDescription} quantity={data.quantity}/>
     
     ) 
}

  </div>
  
  </div>
  
  </div>:null}
<div className=' container-fluid fluid' style={{marginBottom:"5em"}}>

{!basket.length==0?
<div >
<div className='basktTotal' style={i18n.language=="ar"?{textAlign:"left"}:{textAlign:"right"}}>
  <hr/>


 
 
  <p style={{margin:"0 2em"}}>{t("mainMenu.total_price")}</p>
 
  <p style={{color:"#633235"}}>{total} &#160;{t("nav.SAR")} </p>


  </div>
  <form className='basketForm' onSubmit={formik.handleSubmit} >
    <div className="enbNote" >
<label style={{marginTop:"0"}}> {t("basket.address")}</label>

<input type="text" placeholder={t("basket.enterAddress")} value={formik.values.address}  name="address"  ref={addressRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.address && formik.touched.address?"invalid":null}></input>

<div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:null}>
<span>{formik.errors.address && formik.touched.address?t(`${formik.errors.address}`):null}</span>
</div>
<label>{t("basket.notes")}</label>

<input type="text" placeholder={t("basket.enter_your_notes")} value={formik.values.notes}  name="notes"  ref={noteRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.notes && formik.touched.notes?"invalid":null}></input>
<div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:null}>
<span>{formik.errors.notes && formik.touched.notes?t(`${formik.errors.notes}`):null}</span>
</div>

</div>
<div style={i18n.language=="ar"?{textAlign:"left"}:{textAlign:"right"}}>

<button type='submit' className='basketSend'>{t("basket.send")}</button>


</div>
</form>
</div>:
  <div style={{marginTop:"5em",marginBottom:"5em"}} className='container basketEmpty orderSuccess'>
   
 <div className='shareForm isEmpty'>
 <img src={logo}/>
 <p style={{color:"#E47100",fontSize:"20px"}}>{t("basket.isEmpty")} </p>
<div style={{textAlign:"center"}}>
<Link to="/inquires"><button className='addMore' style={{display:"inline"}}> {t("party.addToBasket")} <HiShoppingCart/></button></Link>
</div>
 </div>
  </div>
  }

</div>


<Footer/>
  </div>
    );
}

export default Basket;
