


import '../Styles/Medyaf.css';
import React, {useState} from 'react';
import master from "../Images/Master.svg.png"
import visa from "../Images/Visa.svg.png"
import { BsImages } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
function ChargeWallet() {
    const { t, i18n } = useTranslation();
    return (

<div style={{marginTop:"80px",marginBottom:"5em"}} className='container'>
   
 <div className='shareForm'>
<form  className='chargeForm'>

    <label>{t("profile.chargeWallet")} </label>
    
    <input type='text' placeholder={t("profile.enterBalance")}  />
<div style={{marginTop:"2em"}}>
    <input type='radio' defaultChecked/>
    <label className='paymentType' >{t("profile.creditCardPayment")}</label>
    <div style={{marginTop:"10px"}}>
   <img  src={visa} height="25px"/>
   <img style={{margin:"0 25px"}} src={master} height="35px"/></div>
    </div>
    <button style={{marginTop:"40px"}}>{t("profile.confirm")}</button>
</form>

 </div>
  </div>

    );
}

export default ChargeWallet;
