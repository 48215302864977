


import '../Styles/Medyaf.css';
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import logo from "../Images/MainLogo.svg"
import { useTranslation } from 'react-i18next';
function Modal(props) {
const [quantity,setQuantity]=useState(1);
const { t, i18n } = useTranslation();
const [cartItem,setCartItem]=useState([]);
const [product,setProduct]=useState([]);
const [orderAdded,setOrderAdded]=useState(false);




//sets user cart data 
//sets product data
useEffect(() => { 
  const data= localStorage.getItem("basket");
if(data){
  setCartItem(JSON.parse(data));
 
}


setProduct({

  id:  props.modalData.id,
            mealArName: props.modalData.mealArName,
            mealEnName: props.modalData.mealEnName,
            mealArDescription: props.modalData.mealArDescription,
            mealEnDescription: props.modalData.mealEnDescription,
            mealOriginalPrice: props.modalData.mealOriginalPrice,
            mealImage: props.modalData.image,
            
})


 },[]);


 //checks if item already exists in cart, if yes only changes product quantity in cart if not adds it to the array then stores new basket data in localStorage
function handleAdd(){

const exist= cartItem.find(x => x.id===product.id);

if(exist){
  const dataE=(cartItem.map(x=> x.id===product.id?{...exist, quantity: exist.quantity+quantity}:x))

  localStorage.setItem("basket",JSON.stringify(dataE));
}
else{
  const data=[...cartItem,{...product,quantity:quantity}]

 localStorage.setItem("basket",JSON.stringify(data));
}



 setOrderAdded(true); //to display success message
 setTimeout(function () {
  props.setModal(false)
}, 2000);


}



    return (


        <div className="foodModal">
          {orderAdded}
          {orderAdded===false?
        <div>
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div className="modal-content addItem">
            <div className='vCenter'>
          <div className='modalImage' style={{backgroundImage:`url(${props.modalData.image})`}}>
           </div>
            <div >
             <div className='modalBody'>
            <p className='modalName' style={i18n.language=="ar"?{marginRight:"0",marginLeft:"1em"}:null}>
            {props.modalData.name}
            </p>
            <div className='modalQuantity'>
            <button onClick={()=>setQuantity(quantity+1)} className={i18n.language=="ar"?'modal-btn2':'modal-btn1'}>+</button>
<p>{quantity}</p>
<button onClick={quantity!=1?()=>setQuantity(quantity-1):null}className={i18n.language=="ar"?'modal-btn1':'modal-btn2'}>-</button>
            </div>
            </div>
            <p className='modealDesc'>
            {props.modalData.describe}
             
            </p>
           <div className='modalBottom'>
               <p>   {props.modalData.price * quantity} {t("nav.SAR")}</p>
<button onClick={handleAdd}>{t("party.add_basket")}</button>
           </div>
           
            </div>
            </div>
            <button className="close-modal" onClick={()=>props.setModal(false)}>
              <IoCloseSharp size={40}/>
            </button>
          </div>
        </div>
        
        :
        <div>
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div style={{height:"290px"}} className="modal-content orderAdded">
            <div className='vCenter'>
        
            <div >
             <div className='modalBody' style={{textAlign:"center",display:"block"}}>
               <img className='addedLogo' src={logo}/>
            <p className='modalName' style={i18n.language=="ar"?{marginRight:"0" , maxWidth:"none"}:{maxWidth:"none"}}>
            {t("party.added1")+" "}  <span style={{color:"#e47100"}}>{props.modalData.name} </span> 
            </p>
            <p className='modalName' style={i18n.language=="ar"?{marginRight:"0" , maxWidth:"none"}:{maxWidth:"none"}}>
            {t("party.added2")}
            </p>


            </div>
           
       
            </div>
            </div>
          
          </div>
        </div>
        
        
        
        
        
        }
        </div>

    );
}

export default Modal;
