


import '../Styles/Medyaf.css';
import React,{useEffect,useState} from 'react';
import YourProfile from './YourProfile';
import Footer from '../Components/Footer';
import ProfileNav from '../Components/ProfileNav';
import { CustomerInfo } from '../api';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
function Profile() {
const history=useNavigate()
 const[loading,setLoading]=useState(true)
  useEffect(() => {
  CustomerInfo(localStorage.getItem("token")).then((response) => {
    setLoading(false)
  }).catch(error => {
    if (error.response.status === 401) {
  
       localStorage.removeItem("token");
       localStorage.removeItem("userPhone");
       localStorage.removeItem("userName");
       localStorage.removeItem("basket");
       localStorage.setItem("location","profile");
       history("/login")
    } else {
      
        console.log(error);
    }
});


},[])


    return ( 
<div> 
      
        <div className='userProfile'>
         <ProfileNav active="0"/>
         
         {loading?<div style={{display:"flex",minHeight:"50vh",alignItems:"center",justifyContent:"center",flex:"1"}} className='orderLoader'>
      <BeatLoader color={'#E47100'}  size={30} /></div>:
      <div className='profileBody'>
    <YourProfile/> 
    
    </div>}
   

  </div>
    <Footer/>
    </div>
    );
}

export default Profile;
