import AxiosProvider from "./AxiosProvider";


export const menus = (data) => {
    return AxiosProvider.get('/mdiaf/menus', data)
}

export const singleMenu = (id) => {
    return AxiosProvider.get(`/mdiaf/menus/${id}`)
}
  
export const banner = (language) => {
    return AxiosProvider.get('/banners', {
    params: {
        type: 'mudiaf',
        language: language
    }})
}
export const services = (data) => {
    return AxiosProvider.get('mdiaf/services', data)
}
export const images = (data) => {
    return AxiosProvider.get('mdiaf/images', data)
}

export const phones = (data) => {
    return AxiosProvider.get('infos/mdiaf_phones', data)
}

export const inquire = (data) => {
    return AxiosProvider.get('mdiaf/categories/', data)
}

export const countries = (data) => {
    return AxiosProvider.get('countries', data)
}

export const login = (values) => {
    return AxiosProvider.post('customers/get-token', values,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }})
}
export const SMS = (headers) => {
    return AxiosProvider.get('customers/phone-verification-SMS-send',{
        headers})
}
export const CustomerInfo = (token) => {
    return AxiosProvider.get('customers/account-info',{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }})
} 

export const payment = (id,token) => {
    return AxiosProvider.get('/orders/tap/payment-status/'+id,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }})
}

export const CustomerInfo2 = (token) => {
    return AxiosProvider.get('customers/account-info',{
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }})
}

export const account = (values) => {
    return AxiosProvider.post('customers', values,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }})
}


export const contact = (values) => {
    return AxiosProvider.post('/mdiaf/contact-us', values,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }})
}


export const terms = () => {
    return AxiosProvider.get('infos/terms',{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        params:{
    type:"mdiaf"}
    })
}

export const otp = (values,token) => {
    return AxiosProvider.post('customers/verify-user', values,{
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }})
}

export const usercode = (values) => {
    let params={
        type: "OTP"
    }
    return AxiosProvider.post('customers/verify-user', values,params,{
        headers: {
      
            'Content-Type': 'application/json',
          
        }})
}


export const resendCode = (params) => {
  
    return AxiosProvider.get('customers/OTP-SMS-send',{params, 
        headers: {
      
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          
        }})
} 
export const checkPhone = (params) => {
  
  let  headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };

    return AxiosProvider.get('customers/check-phone',{params, headers})
}

export const forgetOTP = (data) => {
    let headers = {
        'Content-Type': 'application/json',
    };
    let params = {
        type: "OTP",
    };

    return AxiosProvider.post('customers/get-token',data, {
        params,
        headers})
}

export const sendBasket = (values,token) => {
  
    return AxiosProvider.post('mdiaf/inquires',values, {
        headers: {
      
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token,
          
        }})
}
export const changePassword = (values,token) => {
  
    return AxiosProvider.put( 'customers',values, {
        headers: {
      
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token,
          
        }})
}

export const changePassCustomer = (token) => {
    return AxiosProvider.get('customers/account-info',{
        headers: {
          
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token,
        }})
}

export const updateCustomer = (values,token) => {
    return AxiosProvider.put('customers', values, {
        headers: {
          
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }})
}

export const mediafOrder = (token) => {
    return AxiosProvider.get('mdiaf-orders',{
        headers: {
           
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }})
}

export const pay_again = (values,orderId,token) => {
    return AxiosProvider.get('mdiaf-orders/' + orderId + '/payment-url', {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }, body: {values}
})
}


export const book = (values,token )=> {
    return AxiosProvider.post('mdiaf-orders', values,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }})
}
export const singleOrder = (id,token) => {
    return AxiosProvider.get('mdiaf-orders/' +id,{
        headers: {
           
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }})
}

export const cancelOrder = (id,token) => {
    let type = {
        type: "canceled"
    };
    return AxiosProvider.put('mdiaf-orders/' + id + '/cancel', type,{
        headers: {
           
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }})
}

export const payAgain = (id,token) => {
  
    return AxiosProvider.get( 'mdiaf-orders/' + id + '/payment-url',{
        headers: {
           
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }})
}