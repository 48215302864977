


import '../Styles/Medyaf.css';
 
import React, {useState,useEffect,useRef} from 'react';
import LoginNav from '../Components/LoginNav';
import { FiKey } from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import * as yup from "yup"
import {useFormik} from "formik";
import {checkPhone, resendCode,forgetOTP,changePassCustomer,changePassword} from "../api";
import { useNavigate } from "react-router-dom";
import { IoIosPhonePortrait } from "react-icons/io";

function NewPassword() {
const [hidden,setHidden]=useState(false);
let history = useNavigate();
const { t, i18n } = useTranslation();
const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [otp, setOtp]=useState(false);
const [errorMsgModel, setErrorMsgModel]=useState(false);

const [hidden2,setHidden2]=useState(false);

const Schema= yup.object().shape({
    password: yup.string().min(6, ("validation.at_least")).required(("validation.password_required")),
 
    confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], ("validation.password_must_match"))
    .required(("validation.confirm_required"))
    
    })

const formik = useFormik({
  initialValues:{

    password: '',
    confirmPassword: '',
 
  },
  validationSchema: Schema,
  onSubmit

  });
  console.log(formik.errors);
  const passRef=useRef();
 
    const passConfirmRef=useRef();

    useEffect(() => {

       },[]);

    
       function show() {

        setHidden(!hidden);
        var x = document.getElementById("pass1");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }

      function show2() {

        setHidden2(!hidden2);
        var x = document.getElementById("confirmPassword");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }

      async function onSubmit(){
       const token= localStorage.getItem("token");
       console.log(passRef.current.value)
        const data={
       
         password: passRef.current.value
        }
        changePassword(passRef.current.value,token).then((response) => {
          changePassCustomer(token).then((response) => {
          localStorage.setItem("userName",response.data.data.name)
          history("/");
        })
      }).catch(function (error) {
                                  console.log(error)
                                });
      }
      

    return (
   
      <div>
       
        <div>
        <LoginNav/>
        <div className='container-fluid fluid loginContainer'>
<div className='container-fluid login'>


    <div className='loginTitle'>
        <div style={{marginBottom:"2em"}}>
        <FiKey size={60} className='fpLogo'/>
<p className='fp-p1'> {t("Login.forgetYour")}</p>
<p className='fp-p1'> {t("Login.dontWorry")}</p></div>
<form className='loginForm fpForm'  onSubmit={formik.handleSubmit}>
    <div className='fpBody'>
    <div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:null}>
    <label> {t("Login.pass")}</label></div>
<div style={{position:"relative"}}>
<input id="pass1"  type="password" value={formik.values.password}  name="password"  ref={passRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password?"invalid":null}/>
{hidden?
<AiOutlineEyeInvisible onClick={show} size={30} className='eye'style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>:
<AiOutlineEye onClick={show} size={30} className='eye' style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>}
<div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:null}>
<span>{formik.errors.password && formik.touched.password?t(`${formik.errors.password}`):null}</span></div>
</div>
<div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:null}>
<label>  {t("Login.confirmPass")}</label></div>
<div style={{position:"relative"}}>
<input id="confirmPassword"  type="password" value={formik.values.confirmPassword}  name="confirmPassword"  ref={passConfirmRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.confirmPassword && formik.touched.confirmPassword?"invalid":null}/>
{hidden2?
<AiOutlineEyeInvisible onClick={show2} size={30} className='eye' style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>:
<AiOutlineEye onClick={show2} size={30} className='eye' style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>}
<div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:null}>
<span>{formik.errors.confirmPassword && formik.touched.confirmPassword?t(`${formik.errors.confirmPassword}`):null}</span></div>

</div>
{i18n.language=="ar"?
<div style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
<span>{errorMsgModel? msgAr:null}</span></div>:
<div style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
<span>{errorMsgModel? msgEn:null}</span></div>}
<div>
<button type='submit'> {t("Login.confirm")}</button></div></div>
</form>
</div>
</div>
</div>
</div></div>
    );
}

export default NewPassword;
