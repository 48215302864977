


import '../Styles/Medyaf.css';

import React, {useState,useEffect,useRef} from 'react';
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import food from "../Images/Login/Food.svg"
import logo from "../Images/MainLogo.svg"
import brown from "../Images/Login/background.svg"
import LoginNav from '../Components/LoginNav';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from "yup"
import {useFormik} from "formik";
import { useNavigate,useLocation } from "react-router-dom";
import {login,CustomerInfo,SMS} from "../api";
import { BeatLoader } from 'react-spinners';
function Login() {
  const prevRoute = useLocation();
const [hidden,setHidden]=useState(false);
const { t, i18n } = useTranslation();
const [isOldRedirect, setIsOldRedirect]=useState(false);
const [loggedIn, SetoggedIn]=useState(false);
const [loading, setLoading]=useState(false);
const [token, setToken]=useState();
const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [verified, setVerified]=useState(false);
const [errorMsgModel, setErrorMsgModel]=useState(false);
let history = useNavigate();



let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
 
const Schema= yup.object().shape({
phone: yup.string().required(("validation.phone_required")).matches(phoneRegEx,("validation.phone_not_valid")),
password: yup.string().min(6,("validation.at_least")).required(("validation.password_required")),

})


const formik = useFormik({
initialValues:{
  phone:"",
  password:"",
},
validationSchema: Schema,
onSubmit,

});
const passRef=useRef();
    const phoneRef=useRef();




    async function onSubmit(event){
      setLoading(true)
   const data={

    phone: phoneRef.current.value,
    password: passRef.current.value
   }
     
      login(data).then((response) => {
        if (response.data.data.isOld === true) {
            localStorage.setItem("token", response.data.data.access_token);
            localStorage.setItem("userPhone", data.phone)
            setIsOldRedirect(true);
            localStorage.setItem("verified",JSON.stringify(false))
   history("/usercode");
   var frm = document.getElementsByName('loginForm')[0];
  
   frm.reset(); 
    }  
       
        else {
          if (response.data.data.isVerified === true) {
              localStorage.setItem("token", response.data.data.access_token);
              localStorage.setItem("userPhone", data.phone)
              CustomerInfo(response.data.data.access_token).then((response) => {
                localStorage.setItem("userName", response.data.data.name);
                SetoggedIn(true);
                
                setToken(response.data.data.access_token);
                const prev= localStorage.getItem("location");
                var frm = document.getElementsByName('loginForm')[0];
                localStorage.setItem("verified",JSON.stringify(true))
                frm.reset(); 
                  
if(prev=="profile" || prev=="book"|| prev=="basket" || prev=="process" || prev=="payment" || prev=="myOrders"){
              
                history(-1);
              }
              else {
                 
                history("/");
              }
                })}
                else {
                  localStorage.setItem("token", response.data.data.access_token);
                 // setMsgAr("لم يتم تأكيد هذا الحساب من فضلك ادخل رمز التأكيد");
                  //setMsgEn("This account is not verified please enter your code");
                 // setErrorMsgModel(true);
                  localStorage.setItem("verified",JSON.stringify(false))

                  let headers = {
                      'Authorization': 'Bearer ' + response.data.data.access_token,
                      'Accept': 'application/json'
                  };
                  SMS( headers).then((response) => {
                    history("/verify");
                    var frm = document.getElementsByName('loginForm')[0];
                    localStorage.setItem("verified",JSON.stringify(false))
                    frm.reset(); 
                    // console.log(response.data)
                }).catch(function (error) {
                    // console.error(error);
                    setLoading(false)
                });

            }
              
              }}).catch(function(error){
                if (error.response.status === 401 || error.response.status === 422){
              
                    
                        setMsgAr("خطأ في رقم الهاتف او كلمة المرور");

                        setMsgEn("Invalid phone or password");
                        setErrorMsgModel(true);
                        setLoading(false)
  
                }})
  
  }

    

       function show() {

        setHidden(!hidden);
        var x = document.getElementById("pass");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }

    return (
        <div>
             <LoginNav/>
        
        <div className='container-fluid fluid loginContainer'>
            
<div className='container-fluid login'>
{loading?  <div style={{height:"64vh"}} className='loader'>
       <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}
<div style={loading? {display:"none"}:null} className='row'>
<div className='col'>
    <div className='loginTitle'>
<p > {t("Login.login")}</p></div>
<form className='loginForm' name="loginForm" onSubmit={formik.handleSubmit} >

  
<label> {t("Login.enterPhone")}</label>
<input type="text" placeholder= {t("Login.yourPhone")} value={formik.values.phone} id="phone" name="phone"  ref={phoneRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone || errorMsgModel?"invalid":null}/>
<span>{formik.errors.phone && formik.touched.phone?t(`${formik.errors.phone}`):null}</span>
<label>  {t("Login.pass")}</label>
<div className='passAlign' style={{position:"relative",width:"80%"}}>
<input id="pass"  type="password"  value={formik.values.password}  name="password"  ref={passRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password || errorMsgModel?"invalid":null}/>
<span>{formik.errors.password && formik.touched.password?t(`${formik.errors.password}`):null}</span>
{hidden?
<AiOutlineEyeInvisible onClick={show} size={30} className='eye' style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>:
<AiOutlineEye onClick={show} size={30} className='eye' style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>}
<Link to="/forgot"><p style={i18n.language=="ar"?{right:"auto",left:"0"}:null}  className={formik.errors.password && formik.touched.password?"forgot forgotInvalid":"forgot"}> {t("Login.forget")}</p></Link>
</div> 
 
{i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}
<button type="submit" style={{marginTop:"3em"}}> {t("Login.login")}</button>

</form>
<div className='register'>
<p> {t("Login.dontHave")}</p>
<Link to="/register"> <button> {t("Login.register")}</button></Link></div>
</div>
<div className={i18n.language=="ar"?'col loginImagesAr':'col loginImages'}>
<img className='img1' src={brown} />
<img className='img2' src={food} />
<img className='img3' src={logo} />

</div>
</div>
</div>
</div></div>
    );
}

export default Login;
