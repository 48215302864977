


import '../Styles/Medyaf.css';
import React, {useState,useEffect,useContext} from 'react';


import { useTranslation } from 'react-i18next';
import Footer from '../Components/Footer';
import defaultImage from "../Images/defaultImage.jpeg"
import {CustomerInfo,updateCustomer} from "../api";
import { OrderContext } from '../Context/OrderContext';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
function Profile(props) {
const [nav,setNav]=useState(0);
const history= useNavigate();
const{order,setOrder}=useContext(OrderContext);
const { t, i18n } = useTranslation();
const name= localStorage.getItem("userName");
const phone=localStorage.getItem("userPhone");
const [image, setImage]=useState();


if(name!==null){
  var firstName = name.split(' ').slice(0,1).join(' ');}


function handleNavigate(int){
    setNav(int)


}

    return ( 

          <>
              <div className="mobile-upSideNav">
             <div className="mobile-upSideNav-top">
             <div className="upImageBorder">
             {image?    
               <div className='profileBorder'>
             <div className='profileImage' style={{backgroundImage:`url(${image})`}}></div>
</div>:
         <div className='profileBorder'>
       <div className='profileImage' style={{backgroundImage:`url(${defaultImage})`}}></div>
       </div>           } </div>
         <div className="upInfo">
<p className="upInfo-name">{firstName}</p>
<p className="upInfo-number">{phone}</p></div>
       </div>
  <div >
<hr/>
<div className={i18n.language=="ar"?"mobile-upLinks mobile-upLinksAr":"mobile-upLinks"} >
<Link to="/profile"><p onClick={()=>handleNavigate(0)} className={props.active=="0"  ?"upLinksActive":null}>  {t("profile.my_profile")}</p>
</Link>
<Link to="/profile/order"><p onClick={()=>handleNavigate(2)} className={props.active=="1"?"upLinksActive":null}>  {t("profile.my_orders")}</p>

</Link>
<Link to="/profile/logout"><p  onClick={()=>handleNavigate(3)}style={{border:"none"}}className={props.active=="2"?"upLinksActive":null}>   {t("profile.log_out")}</p>
</Link></div>
</div>
         </div>
    <div className='userNav' style={i18n.language=="ar"?{ borderRadius: "10px 0px 0px 10px"}:null}>
    <div className="upImageBorder">
    {image?      
    <div className='profileBorder'>
    <div className='profileImage' style={{backgroundImage:`url(${image})`}}></div>
</div>: <div className='profileBorder'>
          <div className='profileImage' style={{backgroundImage:`url(${defaultImage})`}}></div>
           </div>       }
    </div>
    <div style={{padding:"0 2em"}}>
<p className="upName">{firstName}</p>
<p className="upPhone">{phone}</p></div>
<div style={{marginBottom:"2em"}} className={i18n.language=="ar"?"upLinks upLinksAr":" upLinks  "}>
<Link to="/profile"><p  onClick={()=>handleNavigate(0)} className={props.active=="0" ?"upLinksActive":null}>  {t("profile.my_profile")}</p>
</Link>
<Link to="/profile/order">
<p onClick={()=>handleNavigate(2)} className={props.active=="1"?"upLinksActive":null}>  {t("profile.my_orders")}</p>
</Link>
<Link to="/profile/logout">
<p onClick={()=>handleNavigate(3)} className={props.active=="2"?"upLinksActive":null}>   {t("profile.log_out")}</p>
</Link></div>
    </div>
  </>
        
 
 
    );
}

export default Profile;
