
import '../Styles/Medyaf.css';
import React, {useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { terms } from '../api';
import BeatLoader from "react-spinners/BeatLoader";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import CarouselComp from '../Components/CarouselCom';
function TermsAndConditions() {
    const [arDesc, setArDesc]=useState();
    const [enDesc, SetenDesc]=useState();
    const [loading,setLoading]=useState(true);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        terms().then(response => {
            console.log(response.data.data)
            setArDesc(response.data.data.arDescription);
            SetenDesc(response.data.data.enDescription);
           })
            setLoading(false)
               },[]);
                    


    return (

          
        
            <div >
                 {loading? <div className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
<>

<CarouselComp/>
<div className='container-fluid fluid' >
<p style={{marginTop:"3em"}} className="servicesTitle">{t("nav.t&c")} </p>

<div style={{marginBottom:"3em"}}  dangerouslySetInnerHTML={i18n.language=="ar"?{ __html: arDesc }: { __html: enDesc }} />



</div>
</>
           

} 
              
            </div>
   
    );
}

export default TermsAndConditions;
