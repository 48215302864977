


import '../Styles/Medyaf.css';

import React, {useState,useEffect,useRef} from 'react';
import LoginNav from '../Components/LoginNav';
import { FiKey } from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import * as yup from "yup"
import {useFormik} from "formik";
import {checkPhone, resendCode,forgetOTP,CustomerInfo} from "../api";
import { useNavigate } from "react-router-dom";
import { IoIosPhonePortrait } from "react-icons/io";
function ForgotPassword() {
const [hidden,setHidden]=useState(false);
let history = useNavigate();
const { t, i18n } = useTranslation();
const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [otp, setOtp]=useState(false);
const [errorMsgModel, setErrorMsgModel]=useState(false);

let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
 
const Schema= yup.object().shape({
phone: yup.string().required(("validation.phone_required")).matches(phoneRegEx,("validation.phone_not_valid")),

})


const formik = useFormik({
  initialValues:{

   phone: '',
 
  },
  validationSchema: Schema,
  onSubmit
  });
  console.log(formik.errors);
  const phoneRef=useRef();
  const otpRef =useRef();

    useEffect(() => {

       },[]);

    


      async function onSubmit(event){

        let params = {
          phone: phoneRef.current.value,
      };
      checkPhone(params).then((response) => {
        if (response.data.data.check === true) {
          localStorage.setItem("phone",JSON.stringify(params.phone));
        //  localStorage.setItem("phone",JSON.stringify(params.phone));
          //  document.getElementById("confirmCode").style.display = "block";
            //document.getElementById("phoneNum").style.display = "none";
            setOtp(true);
            console.log(params);
           
        } else {
     
    
              setMsgAr("رقم الهاتف غير صحيح");
              setMsgEn("Wrong phone number");
                setErrorMsgModel(true);
        
        }
       

    }).catch(function (error) {
        console.error(error);
    }); resendCode(params).then((response) => {

   
      console.log(response.data);
  }).catch(function (error) {
       console.error(error);
  });

      }
  
      function resend(){


        let params = {
            phone: phoneRef.current.value
        };
        resendCode(params).then((response) => {
       
                setMsgAr("تم إرسال الكود");
                setMsgEn("Your code has been send");
                setErrorMsgModel(true);
         
        }).catch(function (error) {
            // console.error(error);
        });
    };

function submitCode(e){
  e.preventDefault();
 const phone= JSON.parse(localStorage.getItem("phone"));
 console.log(phone);

let data = {
    phone: phone,
    OTP:  otpRef.current.value
};
  forgetOTP(data).then((response) => {
    if (response.data.message === "success") {
        localStorage.setItem("token", response.data.data.access_token)
        CustomerInfo(response.data.data.access_token).then((response) => {
            localStorage.setItem("userName", response.data.data.name);
            history("/new-password");
    })
      }
    
    
      else {

                 
        setMsgAr("الكود غير صحيح")
        setMsgEn("Invalid code");
        setErrorMsgModel(true);
        console.log(response);
  }


}).catch(function (error) {
  setMsgAr("الكود غير صحيح")
          setMsgEn("Invalid code");
          setErrorMsgModel(true);
      
      

    })

}
 
    return (
   
      <div>
        {otp? 
         <div><LoginNav/>
         <div className='container-fluid fluid loginContainer'>
 <div className='container-fluid login'>
 
 
     <div className='loginTitle'>
         <div style={{marginBottom:"2em"}}>
         <IoIosPhonePortrait size={60} className='fpLogo'/>
 <p className='fp-p1'>{t("Login.checkPhone")}</p>
 <p className='fp-p1'>{t("Login.verify")}</p></div>
 <form className='loginForm fpForm' onSubmit={(e)=>submitCode(e)}>
     <div className='fpBody'>
 <div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:null}>
 <label>{t("Login.EnterVerify")}</label>
 <div>
 
 <input type="text"  id="code" name="code"  ref={otpRef} className={errorMsgModel?"invalid":null}/>
 </div></div>
 
 <div>
 <div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:null}>
 {i18n.language=="ar"?
 <span>{errorMsgModel? msgAr:null}</span>:
 <span>{errorMsgModel? msgEn:null}</span>}
 <p onClick={resend} className='resend'>{t("validation.resend")}</p>
 </div>
 <button type='submit'>{t("Login.confirm")}</button></div></div>
 </form>
 </div></div>
 </div>
 
 </div>











:
        <div>
        <LoginNav/>
        <div className='container-fluid fluid loginContainer'>
<div className='container-fluid login'>


    <div className='loginTitle'>
        <div style={{marginBottom:"2em"}}>
        <FiKey size={60} className='fpLogo'/>
<p className='fp-p1'> {t("Login.forgetYour")}</p>
<p className='fp-p1'> {t("Login.dontWorry")}</p></div>
<form className='loginForm fpForm' onSubmit={formik.handleSubmit} >
    <div className='fpBody'>
<div  className='fpFormLabel'style={i18n.language=="ar"?{textAlign:"right"}:null}>
<label> {t("Login.enterPhone")}</label></div>
<input type="text" placeholder={t("Login.yourPhone")} value={formik.values.phone} id="phone" name="phone"  ref={phoneRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone || errorMsgModel?"invalid":null}/>
<div  className='fpFormLabel'style={i18n.language=="ar"?{textAlign:"right"}:null}>
<span>{formik.errors.phone && formik.touched.phone?t(`${formik.errors.phone}`):null}</span></div>
{i18n.language=="ar"?
<div style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
<span>{errorMsgModel? msgAr:null}</span></div>:
<div style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
<span>{errorMsgModel? msgEn:null}</span></div>}
<div>
<button type='submit'> {t("Login.confirm")}</button></div></div>
</form>
</div>
</div>
</div>
</div>}</div>
    );
}

export default ForgotPassword;
