


import '../Styles/Medyaf.css';
import React, {useState,useEffect,useRef} from 'react';
import MenuRecommendation from '../Components/MenuRecommendation';
import MenuMeals from '../Components/MenuMeals';
import Navbar from '../Components/Navbar';
import CarouselComp from '../Components/CarouselCom';
import { Link } from 'react-router-dom';
import SideNavLink from '../Components/SideNavLink';
import { useTranslation } from 'react-i18next';
import { singleMenu ,menus} from '../api'
import {useLocation} from 'react-router-dom'
import Footer from '../Components/Footer';
import BeatLoader from "react-spinners/BeatLoader";
import { useParams,generatePath } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Navigation } from "swiper";

function MenuDetails() {
  const { t, i18n } = useTranslation();
  const[menuCategories,setMenuCategories]=useState([]);
  const [meals,setMeals]=useState(0) //active category index
  const [categoryMeal,setCategoryMeal]=useState([]);
  const [loading,setLoading]=useState(true);
  const[mdiafMenus,setMdiafMenus]=useState([]);
  const[menuData,setMenuData]=useState([]);
 
  const { id } = useParams();
  const topRef = useRef()
  const location= useLocation();
  let history = useNavigate();
 
 
  //menus api call to fetch medyaf menus (to display in "other menus" section)
  //singleMenu api call takes menu id from url to fetch details of a specific menu
  //rerenders on url change when another menu is chosen from MenuRecomendation component found in /components
  //also rerenders when new category is chosen ( const [meals,setMeals] )
  useEffect(() => {
    window.spix('event','lead');
    menus().then(response => {
      const menu=response.data.data.mdiafMenus;
      setMdiafMenus(menu);
     // setMenuData(menu[id-1])
   
      console.log(menu);
    })

    singleMenu(id).then(response => {
     const data=response.data.data.mdiafMenuCategories;
     setMenuCategories(data);
     setMenuData(response.data.data);
     setCategoryMeal(response.data.data.mdiafMenuCategories[meals].mdiafMenuMeals);  //set displayed meals according to current active category index "[meals]"
     

setLoading(false); 
}).catch(function (error) {
  if(error.response.status === 422){
  history('/err');}
  })

  //scrolls view to the div with "topRef" as ref
if(!loading){
  topRef.current.scrollIntoView({ behavior: 'smooth' });
}

 },[location,meals]);


  //on clicking book menu user is redirected to /menu/:id/booking (BookMenu component found in /Medyaf) with menu id as params

function handleBook(){
  console.log("click");
  history(generatePath("/menu/:id/booking", { id }));

}
 
    return (
      <div>{loading?<div className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
<div ref={topRef}>
<CarouselComp/>
    
    <div className='innerNav1 fluid container-fluid'  >
   
      {menuData?
        <p className='itemName'>{i18n.language=="ar"?menuData.arName:menuData.enName}</p>
    :null} <p>

        {t("mainMenu.min_persons")} {menuData.minPersonsNumber} {t("mainMenu.person")}</p>
<div className='bookDiv'>
{mdiafMenus?   <p className={i18n.language=="ar"?'itemPriceAr':'itemPrice'}>{menuData.pricePersonSAR}  {t("nav.SAR")}
        
  </p>  :null}
        <button onClick={handleBook}>{t("mainMenu.book_menu")}</button>

        </div>
        </div> 

         
<div  className='container-fluid fluid'  >
    <div className='bodyMargin2'  >
      <div className="normalCategory">
      <p className='productsTitle'>{t("mainMenu.categories")}</p>
<div style={{width:"270px"}}  className={i18n.language=="ar"?'sideNavAr':'sideNav'}>
{menuCategories && menuCategories.map((data,index)=>
     <SideNavLink style={i18n.language=="ar"?{borderRight:"5px solid #e47100", backgroundColor: "rgba(228, 113, 0, 0.07)"}:{borderLeft:"5px solid #e47100", backgroundColor: "rgba(228, 113, 0, 0.07)"}} index={index} meals={meals} setMeals={setMeals} key={index} id={data.id} image={data.image} name={i18n.language=="ar"?data.arName:data.enName}/>
     
     ) 
}

</div>
</div>
<div style={{width:"100%"}}>

<div className='topCategories'>
 
<p className='productsTitle'>{t("mainMenu.categories")}</p>
<div className='mobileSideLink' >
{menuCategories && menuCategories.map((data,index)=>
     <SideNavLink style={{borderBottom:"5px solid #e47100", backgroundColor: "rgba(228, 113, 0, 0.07)"}} index={index} setMeals={setMeals} key={index} id={data.id} image={data.image} meals={meals} name={i18n.language=="ar"?data.arName:data.enName}/>
     
     ) 
} 
</div>
</div>
<div className='productsBook productsBookHeight'>
<div style={{marginTop:"3.7em"}} className='container-fluid menuMealDiv' >
<div style={{marginBottom:"100px",width:"100%"}} className="row gy-4">

{categoryMeal && categoryMeal.map((data,index)=>
     <MenuMeals quantity={data.pivotMdiafMenuMdiafCategory.quantity} key={index} id={data.mdiafProduct.id} image={data.image}  name={i18n.language=="ar"?data.mdiafProduct.arName:data.mdiafProduct.enName} describe={i18n.language=="ar"?data.mdiafProduct.arDescription:data.mdiafProduct.enDescription}/>
     
     ) 
}
  </div></div></div> </div>
</div>
  </div>
  <div className={i18n.language=="ar"?'otherMenusAr':'otherMenus'}>

      <p className='other'>{t("mainMenu.anotherMenus")} </p>
   
                
  <div  className='reccomendationScroll'>
 
 
 
<Swiper 
navigation={true} 
dir={i18n.language=="ar"?"rtl":"ltr"}
spaceBetween={10}
key={i18n.language}
breakpoints={{
  100: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  630: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 10,
  },
}}

loop={true}
loopFillGroupWithBlank={true}
modules={[Navigation]} 

className={i18n.language=="ar"?"swiperRecommend btnsAr":"swiperRecommend btnsEn"}
>
        
          
        {mdiafMenus && mdiafMenus.map((data,index)=>
        <SwiperSlide>
     <MenuRecommendation key={index} id={data.id} image={data.image} min={data.minPersonsNumber} price={data.pricePersonSAR} name={i18n.language=="ar"?data.arName:data.enName}/>
     
     </SwiperSlide>
     ) 
}

        
      </Swiper>           
</div>    
  </div>
  <Footer/>
  </div>}</div>
    );
}

export default MenuDetails;
