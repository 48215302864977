


import '../Styles/Medyaf.css';
import React, {useState,useEffect} from 'react';
import master from "../Images/Master.svg.png"
import visa from "../Images/Visa.svg.png"
import { BsImages } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import Footer from '../Components/Footer';
import logo from "../Images/MainLogo.svg"
function BasketSent() {
    const { t, i18n } = useTranslation();
 
  
    return (
<div>
<div style={{marginTop:"5em",marginBottom:"5em"}} className='container orderSuccess'>
   
 <div className='shareForm '>
 <img src={logo}/>
<p >
{t("basket.inquireSuccess1")}<br/>
{t("basket.inquireSuccess2")}
</p>
 </div>
  </div>
  <Footer/>
  </div>
    );
}

export default BasketSent;
