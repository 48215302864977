


import '../Styles/Medyaf.css';
import React, {useState,useEffect,useContext} from 'react';

import burger from "../burger.jpeg"
import YourProfile from './YourProfile';
import YourWallet from './YourWallet';
import Logout from './Logout'
import YourOrders from './YourOrders';
import { useTranslation } from 'react-i18next';
import Footer from '../Components/Footer';
import defaultImage from "../Images/defaultImage.jpeg"
import {CustomerInfo,updateCustomer} from "../api";
import { OrderContext } from '../Context/OrderContext';
import ProfileNav from '../Components/ProfileNav';
function Order() {


    return ( 
<div>
      
        <div className='userProfile'>
         <ProfileNav active="1"/>
        
    
      <div className='profileBody'>
    <YourOrders/> 
    
    </div>
   

  </div>
    <Footer/>
    </div>
    );
}

export default Order;
