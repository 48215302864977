
 
import '../Styles/Medyaf.css';
import React, {useState,useEffect} from 'react';
import Navbar from '../Components/Navbar';
import CarouselComp from '../Components/CarouselCom';
import Services from '../Components/Services';
import Footer from '../Components/Footer';
import { services } from '../api'
import { useTranslation } from 'react-i18next';
import BeatLoader from "react-spinners/BeatLoader";
function AdditionalServices() {
  const { t, i18n } = useTranslation();
  const[service,Setservice]=useState([]);

const [loading,setLoading]=useState(true);


//services api call to fetch medyaf services
useEffect(() => {
  services().then(response => {
   const data=response.data.data.mdiafServices;
   Setservice(data);

console.log(response);
setLoading(false);
})

 

 },[]);
    return (
      <div>{loading?<div className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
      <div>      <CarouselComp/>
    
<div style={{marginTop:"30px",marginBottom:"5em"}} className='container-fluid fluid'>
<p className="servicesTitle">{t("nav.mudiaf_services")} </p>
  <div class="row gy-5"> 
  {service && service.map((data,index)=>
     <Services key={index} image={data.featuredImg}  price={data.price} name={i18n.language=="ar"?data.arName:data.enName} describe={i18n.language=="ar"?data.arDescription:data.enDescription}/>
     
     )
}
  
  </div></div>
  <Footer/>
  </div>} </div>
    );
}

export default AdditionalServices;
