
import {GrClose} from 'react-icons/gr'
import { useState,useContext,useEffect } from 'react';
import burger from "../burger.jpeg"
import { useTranslation } from 'react-i18next';
import { BsPlusLg } from 'react-icons/bs';
import { HiMinusSm } from 'react-icons/hi';
import { RiDeleteBin5Line } from "react-icons/ri";
function BasketItems(props) {

  const [quan,setQuan]=useState(props.quantity);
  const { t, i18n } = useTranslation();
const [cartItem,setCartItem]=useState();
 
 //sets cart data
useEffect(() => {
  const data= localStorage.getItem("basket");
if(data){
  setCartItem(JSON.parse(data));

} },[]);


//opens DeleteItem modal and sets id of the meal that will be deleted
function handleDel(){

props.setDelId(props.id)

props.setModal(true);

}


//on adding meal quantity this function takes meal id and loops through basket to find matching id then adds the quantity of meal with matching id
//then localstorage item "basket" is set to new value
function handleQuantityAdd(id){ 
  const data= JSON.parse(localStorage.getItem("basket"));

  const exist= data.find(x => x.id===id);
  const dataE=(data.map(x=> x.id===id?{...exist, quantity: quan+1}:x))
 
  localStorage.setItem("basket",JSON.stringify(dataE));
  setQuan(quan+1)
  props.setChangeQuan(!props.changeQuan);
}
 
//on adding meal quantity this function takes meal id and loops through basket to find matching id then subtracts from the quantity of meal with matching id
//then localstorage item "basket" is set to new value
function handleQuantityRemove(id){

  const data= JSON.parse(localStorage.getItem("basket"));
  if (quan!=1){
   
    const exist= data.find(x => x.id===id);
    const dataE=(data.map(x=> x.id===id?{...exist, quantity: quan-1}:x))
  
    localStorage.setItem("basket",JSON.stringify(dataE));
    setQuan(quan-1);
    props.setChangeQuan(!props.changeQuan);
  }
 
}

  return (<div className='col-12 basket'  style={{position:"relative"}}>
<div className='bItem'>
  <div className='container-fluid'>
<div className=' row'>
<div className='col-md-6 bCenter'>
<div className='basketImg' style={{backgroundImage:`url(${props.image})`}}></div>
<div style={{margin:"0 1em"}}>
         <p className= "enbName">{props.name}</p>
         <p className='bDetails'>{props.describe}</p>
         </div>
        </div>

<div className='col-md-3 col-6 alignQuan'>
<p>{t("mainMenu.quan")}</p>
<div>

<button  onClick={()=> handleQuantityRemove(props.id)} className='bBtn bBtnMinus'><HiMinusSm size={30}/></button> 
<p className='bQuan'>{quan}</p> 

<button  onClick={()=> handleQuantityAdd(props.id)} className='bBtn '><BsPlusLg size={20}/></button> 
</div>
</div>

<div className='col-md-2 col-6 alignQuan'>
<p>{t("mainMenu.price")}</p>
<div>
<p className='bItemPrice'>{props.price * quan}  {t("nav.SAR")}</p>
</div>
</div>

        {/*
        <div  className='col-6 bAlign'>
            <div className='bFlex'>
         
     <GrClose onClick={()=>props.handleClose(props.id)} style={{cursor:"pointer"}} size={25}/>
         
         </div>
         <div className='bBottom bFlex'>
         <p  className='bPrice'>{props.price * quan}  {t("nav.SAR")}</p>
             <div>

            <button  onClick={()=> handleQuantityRemove(props.id)} className='bBtn'>-</button> 
            <p className='bQuan'>{quan}</p> 

            <button  onClick={()=> handleQuantityAdd(props.id)} className='bBtn'>+</button> 
            </div>
        </div>
        </div> */
}

        </div>
        </div>
</div>
<RiDeleteBin5Line  className='removeItem' onClick={handleDel} style={i18n.language=="ar"?{left:"0.5em",right:"auto"}:null} size={30}/>
</div>)}

export default BasketItems;