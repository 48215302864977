
import '../Styles/Medyaf.css';
import React, {useState,useEffect} from 'react';
import { banner } from '../api'
import Carousel from 'react-bootstrap/Carousel'
import i18next from 'i18next';
import { Swiper, SwiperSlide } from "swiper/react";
import { BackgroundImage } from 'react-image-and-background-image-fade'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation ,Autoplay} from "swiper";
import { useTranslation } from 'react-i18next';
function CarouselComp(props) {
  const[banners,setBanners]=useState([]);

  const [loading,setLoading]=useState(true);
  const { t, i18n } = useTranslation();
  

  //banner api call to fetch carousel images
  useEffect(() => {
    let language= i18n.language=="ar"? "ar":"en"
    banner(language).then(response => {
     const data=response.data.data;
    setBanners(data);


})



   },[i18n.language]);
   
    return (
<div className='container-fluid fluid' style={{marginTop:"2em"}}>
            <div className={i18n.language=='ar'?'crslAr':null}>
              


                <Swiper
                  dir={i18n.language=="ar"?"rtl":"ltr"}
                 style={{
                
                    "--swiper-pagination-color": "#E47100",
                   "border-radius" :"20px"
                  }}
pagination={{
    clickable: true,

 
}}
key={i18n.language}
navigation={true}
centeredSlides={true}
loop={true}
spaceBetween={30}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}

     
modules={[Pagination, Navigation,Autoplay]}
className={i18n.language=="ar"?"myCarousel btnsAr":"myCarousel btnsEn"}
>
{banners && banners.map((data,index)=>


<SwiperSlide key={index}>  
 { /*<img
      className="d-block w-100"
      src={data.image}
      alt="First slide"
/>*/}
{/*
<div  className='crslBackground' style={{ backgroundImage: `url(${data.image})` }}>

</div>*/}
 
<BackgroundImage
        src={data.image}
       width="100%"
       lazyLoad
       className="crslBackground"
      />
     </SwiperSlide>


   
     
     )
}</Swiper>

</div></div>
    );
}

export default CarouselComp;
