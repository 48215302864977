


import '../Styles/Medyaf.css';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-image-and-background-image-fade'

function SideNavItem(props) {
    const { t, i18n } = useTranslation();
const [active,setActive]=useState("0");
    function handleClick(){

props.setMeals(props.index)
setActive(props.index)
console.log(props.index)
props.setChangeLoad(true);
    }

    return ( 
//sideLinksActive  background-color: rgba(228, 113, 0, 0.07);

    
<div onClick={handleClick} style={props.meals===props.index?props.style:null} className={i18n.language=="ar"?'sideLinksAr':'sideLinks'}>
{/*<img src={props.image}/>*/}
<Image src={props.image}/>
<p>{props.name}</p></div>


    ); 
}

export default SideNavItem;
 