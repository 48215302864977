


import '../Styles/Medyaf.css';
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import burger from "../burger.jpeg"
import { useTranslation } from 'react-i18next';
function TermsModal(props) {
const [quantity,setQuantity]=useState(1);
const { t, i18n } = useTranslation();
const [cartItem,setCartItem]=useState([]);
const [product,setProduct]=useState([]);







    return (


        <div  className="foodModal">
          { console.log(cartItem)}
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content"  style={{width: "90%", maxWidth: "400px" ,minWidth: "265px"}}>
          
            <div style={i18n.language=="ar"?{overflow: "auto", height: "100%"}:{overflow: "auto", height: "100%",direction:"rtl"}}>
             <div  className='modalBody tcBody'>
             <div dangerouslySetInnerHTML={{ __html: props.describe }} />
           
            </div>
          
     
            </div>
            <button className="close-modal" onClick={()=>props.setModal(false)}>
              <IoCloseSharp size={40}/>
            </button>
          </div>
        </div>
    

    );
}

export default TermsModal;
