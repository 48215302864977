import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {Link} from "react-router-dom";
import success from "../Images/success.svg";
import failed from"../Images/faild.svg";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import Footer from '../Components/Footer';
import BeatLoader from "react-spinners/BeatLoader";
//import {RESETAPP} from "../actions/cartActions";
//import connect from "react-redux/es/connect/connect";
import logo from "../Images/MainLogo.svg"
import { useNavigate } from "react-router-dom";
import {payment ,pay_again} from "../api";


//user is redirected here from third party service
function PaymentSuccess(){
 const  [loading,setLoading]=useState(true);
 const { t, i18n } = useTranslation();
  
  const [state,setState]=useState(false);
   const [orderOption,setOrderOption] =useState(false);
   const [paymentLoad,setPaymentLoad]=useState(false);
   const { payId } = useParams();
   const search = window.location.search;
   const params = new URLSearchParams(search);

   const orderId = parseInt(params.get('TrackId'));
   const TranId = params.get('TranId');
   let history = useNavigate();
    
//payment api call takes user token and tapId(from url) and returns success or failure of payment operation (if failed goes into catch)
//screen display is set according to operation status (success/fail)
    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let tapId = params.get('tap_id');
 

        if(tapId !== ""){
            payment(tapId ,localStorage.getItem("token")).then((response) => {
                setState(true)
                setLoading(false);
               
               
        
            }).catch(function (error) {
                if (error.response.status === 401) {
      
                    localStorage.removeItem("token");
                    localStorage.removeItem("userPhone");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("basket");
                    localStorage.setItem("location","payment");
                    history("/login")
                 }

                setState(false)
                setLoading(false);
              
             
            });
            
        } else {
            setState(false)
            setLoading(false);
           
        }} ,[ ]);


        //if operation was successful and user clicks on track order
        // user is redirected to /order-process (OrderProcess component found in /Medyaf) with order id as params
        function handleProceed () {
            var id=localStorage.getItem("orderId");
            history(generatePath("/order-process/:id", { id }));
            
          }; 

      //if operation failed and user clicks on pay again
      //pay_again api call takes Tranid from url and redirects user to third party service
   function payAgain(){ 
    setPaymentLoad(true);
        let values = {
            transactionId: TranId
        }; 
        pay_again(values,localStorage.getItem("orderId"),localStorage.getItem("token")).then((response) => {
           window.location.href = response.data.data.url
          
        }).catch(function (error) {
            if (error.response.status === 401) {
      
                localStorage.removeItem("token");
                localStorage.removeItem("userPhone");
                localStorage.removeItem("userName");
                localStorage.removeItem("basket");
                localStorage.setItem("location","payment");
                history("/login")
             }
            // console.log(error.response)
            setPaymentLoad(false);
        });
    }

     
        return (
          <div>{loading?<div className='loader'>
          <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

<div style={{marginTop:"5em",marginBottom:"5em"}} className='container orderSuccess'>
  
{state? <div className='shareForm '>
   <img style={{marginBottom:"1em"}} src={success}/>
 
  <p style={{color:"rgb(99, 50, 53)"}}>
  {t("payment.success_payment")}
  </p>
  
<button style={{display:"inline-block"}} onClick={handleProceed}> {t("payment.track_order")}</button>
   </div>:


<div className='shareForm '>
   <img style={{marginBottom:"1em"}}  src={failed}/>
 
  <p style={{color:"rgb(99, 50, 53)"}}>
  {t("payment.notSuccess_payment")}
  </p>

{paymentLoad?

<div style={{marginTop:"50px"}} className='loaderDiv'>

<BeatLoader color={'#E47100'} loading={paymentLoad}  size={30} /></div>:

<button style={{display:"inline-block"}} onClick={payAgain}> {t("payment.pay_again")}</button>}
   </div>
    
    
   }
    </div>
                


                
                }
                <Footer/>
                </div>
           
        )
    }


    export default PaymentSuccess;
{/*

const mapDispatchToProps = (dispatch) => {

    return {
        RESETAPP: () => {
            dispatch(RESETAPP())
        }

    }
};
export default connect(null, mapDispatchToProps)(paymentSuccess)*/}