
import '../Styles/Medyaf.css';
import React, {useState} from 'react';
import burger from "../burger.jpeg"
import { useTranslation } from 'react-i18next';
import { propTypes } from 'react-bootstrap/esm/Image';
import { Link } from 'react-router-dom';
import { BackgroundImage } from 'react-image-and-background-image-fade'

function MenuRecommendation(props) {
  const { t, i18n } = useTranslation();
    return (

 <div style={{margin:"0.5em 0",paddingBottom:"1em"}}>
 
      <div className=" medyafMenu reccomendation">
   {/*   <div className='bckg' style={{backgroundImage:`url(${props.image})`}}></div>*/}

   <BackgroundImage
        src={props.image}
       width="100%"
       lazyLoad
       className="bckg"
      />

      <div className='p-3'><p>{props.name}</p>
      <div className='medyafDetails'>
      <p> <span>{props.price}</span>   {t("nav.SAR")}</p>
      <p>  {t("mainMenu.min_persons")} <span>{props.min}</span>  {t("mainMenu.person")}</p></div>
   <Link to={`/menu/${props.id}`}>   <button>{t("mainMenu.openMenu")}</button></Link>
      </div>
      </div>
      </div>
   
    );
}

export default MenuRecommendation;
